export default {
  info: 'Информация',
  plot: 'Земля',
  new_building: 'Новая постройка',
  object: 'Объект',
  bought_plots: 'Участков земли',
  cost: 'Стоимость',
  construction_time: 'Время строительства',
  not_enough_plots: 'У Вас недостаточно участков земли для строительства',
  buy_plots: 'Купить землю',
  free_plots: 'Свободные участки: {amount} шт.',
  plot_plots: 'участ|ок|ка|ков',
  plots_count: 'Сколько участков земли вы хотите купить?',
  general_count: 'Общая сумма: ',
  success_object_buy: 'Вы успешно начали строительство объекта!',
  success_plots_buy: 'Вы успешно приобрели землю!',
  building: 'Строится',
  built: 'Построен',
  available_plots: 'Участков земли в наличии: {amount}',
}
