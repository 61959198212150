export default {
  test: 'Тест',
  achievements: 'Достижения',
  not_achievements: 'Пока что у менеджера нет достижений',
  created_at: 'Дата регистрации: {date}',
  last_active_at: 'Последний заход: {date} назад',
  profile_modal_save: 'Сохранить',
  edit_button: 'Редактировать',
  moderate_button: 'Модерировать',
  not_country: 'Нет страны',
  tokens: {
    main: 'Web Coin',
    recovery: 'Токен восстановления',
    power: 'Токен силы',
    pump: 'Токен прокачки игроков',
    loyalty: 'Токен лояльности',
  },
  profile_ban_to: 'Заблокирован до: {date}',
  profile_mut_to: 'Замутан до: {date}',
  block: {
    ban_tab: 'Бан',
    mut_tab: 'Мут',
    ban: 'Забанить',
    unban: 'Снять бан',
    mut: 'Замутать',
    unmut: 'Снять мут',
    forefer: 'Навсегда',
    ban_to: 'Бан - до {date}',
    mut_to: 'Мут - до {date}',
    reason_info: 'Причина - {reason}',
    not_block: 'Заблокирован - нет',
    days: 'Дней',
    minutes: 'Минут',
    seconds: 'Секунд',
    reason_textarea_lable: 'Причина',
    submit: 'Сохранить',
    action_success: 'Операция была выполнена успешно',
  },
  tokens_short: {
    main: 'основной',
    recovery: 'восстановления',
    power: 'силы',
    pump: 'прокачки игроков',
    loyalty: 'лояльности',
  },
  manager: 'Менеджер',
  friends: 'Друзья',
  friend: 'Друзья {amount}',
}
