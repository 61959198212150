import api from '@/http'

const state = () => ({})

const getters = {}

const actions = {
  banManager(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`/block/${payload.id}/ban`, payload.body)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  mutManager(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`/block/${payload.id}/mut`, payload.body)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  unbanManager(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`/block/${payload.id}/unban`, payload.body)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  unmutManager(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`/block/${payload.id}/unmut`, payload.body)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
