export default {
  profile_manager: 'Профиль',
  profile_referrals: 'Рефералы',
  profile_friends: 'Друзья',
  referrals_invitation: 'Приглашай в игру друзей по своей реферальной ссылке: ',
  my_referrals: 'Мои рефералы',
  login: 'Логин',
  country: 'Страна',
  team: 'Команда',
  elo_rating: 'Рейтинг Эло',
  level: 'Уровень',
  joined: 'Присоединился',
  was_online: 'Был(а) в сети',
  online_interval: '{interval} назад',
  online: 'Онлайн',
  current_manager: 'Менеджер {manager}',
}
