export default {
  championships: 'Чемпионаты',
  cups: 'Кубки',
  seasons: 'Сезоны:',
  season: 'Сезон',
  tournament_total_info:
    '{name} состоит из двадцати команд. Игры проводятся в два круга (дома и в гостях) Команды занявшие первые места в следующем сезоне примут участие в Лиге Чемпионов и в Лиге Европы. Количество команд зависит от коэффициента страны.',
  tournament_detail: 'Перейти',
  tournament_positions: 'Топовые позиции',
  awards_table_title: 'Призовые бонусы',
  euro_tornaments_title: 'Евро Кубки в следующем сезоне',
  champions_league: 'Лига Чемпионов',
  europa_league: 'Лига Европы',
  calendar: 'Календарь',
  modal_title: 'Расписание лиги',
  place: 'место',
  vip_for_three_mounth: 'ВИП на 3 месяца',
  tournament_cup_info:
    'В соревновании {cup_name} участвуют все команды играющие в чемпионате страны {country_name}. Каждый тур состоит из двух встреч. Победитель кубка принимает участие в групповом этапе Лиги Европы.',
  not_best_players_data: 'Пока что нет подходящих игроков',
  statistic_title: 'Статистика',
  tab: {
    statistic_goals: 'Бомбардиры',
    statistic_red_cards: 'Красные карточки',
    statistic_shoots: 'Кол-во ударов',
    statistic_time: 'Время на поле',
    statistic_yellow_cards: 'Желтые карточки',
  },
  places: {
    team: 'Команда',
    played: 'Игр',
    won: 'Выигрыш',
    drawn: 'Ничья',
    lost: 'Поражения',
    goals_for: 'Голы',
    goals_against: 'Пропущен. мячи',
    points: 'Очков',
  },
  places_short: {
    team: 'Команда',
    played: 'И',
    won: 'В',
    drawn: 'Н',
    lost: 'П',
    goals_for: 'ЗГ',
    goals_against: 'ПГ',
    points: 'О',
  },
  statistic: {
    player_name: 'Футболист',
    player_role: 'Позиция',
    player_age: 'Возраст',
    team: 'Клуб',
    goals: 'Кол-во голов',
    shoots: 'Кол-во ударов ',
    time: 'Время на поле (мин)',
    yellow_cards: 'Кол-во ЖК',
    red_cards: 'Кол-во КК',
  },
  tournament_statistic: {
    tournament_teams: 'Команды',
    tournament_best_players: 'Лучшие игроки лиги',
    tournament_infrastructure: 'Инфраструктура',
    tournament_finished_transfers: 'Трансферы лиги',
    team: 'Клуб',
    full_name: 'Полное имя',
    role: 'П',
    level: 'Уровень',
    cost: 'Стоимость',
    parameters: 'Параметры',
    player: 'Футболист',
    transfer: 'Трансфер',
    type: 'Вид',
    data: 'Дата',
    sum: 'Сумма',
    full_cost: 'Стоимость всего',
    stadium: 'Стадион',
    training_base: 'База клуба',
    spa: 'СПА-центр',
    medical_center: 'Реабилитац. центр',
    no_transfers: 'На данный момент в лиге нет трансферов',
    budget: 'Бюджет',
    players_count: 'Количество игроков',
    avg_age: 'Средний возраст',
    object_cost: 'Стоимость объекта: {cost}',
  },
}
