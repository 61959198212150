import _ from 'lodash'

export default {
  methods: {
    getPlayerImage(player, template = 'player_small') {
      if (player?.image_url_pattern === null) {
        return null
      }

      return _.replace(player?.image_url_pattern, '{template}', template)
    },
    getBaseUrl() {
      return _.trimEnd(process.env.VUE_APP_API_URL, '/')
    },
    getStaticUrl() {
      return this.getBaseUrl() + '/static'
    },
    getCountryImageByCode(code, type) {
      return `${this.getStaticUrl()}/country_images/${type}/${code}.png`
    },
  },
}
