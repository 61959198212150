export default {
  msg_to_admin: 'Сообщение администрации',
  enter_name: 'Введите имя',
  enter_contact: 'Введите Email или Telegram',
  enter_content: 'Введите сообщение',
  success_msg: 'Вы успешно отправлили сообщение администрации!',
  textarea_validation_message: 'Количество символов должно быть не меньше 10.',
  info: 'Информация',
  msg_to_telegram:
    'Для получения более быстрого ответа напишите нам в телеграм: ',
  admin_contact: 'Связь с администрацией',
  contact_us: 'Свяжитесь с нами любым удобным для вас способом: ',
  send_msg: 'Оставить сообщение для обратной связи',
  send_msg_mobile: 'Оставить сообщение',
  faq_no_answer: 'Кликните на вопрос чтобы увидеть ответ',
}
