export default {
  bank: 'банк',
  bank_description:
    'Здесь вы можете взять кредит под залог построенной недвижимости (стадиона или тренировочной базы). Кредит не может превышать 30% от стоимости недвижимости. Долг банку ежедневно будет возрастать на сумму равную 0.1% от текущего долга. Вы сами решаете когда погашать долг и на какую сумму, но если сумма долга превысит 75% от стоимости недвижимости, деньги будут принудительно сняты с Вашего счета, а если на счету не будет денег то недвижимость будет конфискована без права возврата.',
  virtual_dollars: 'ВД',
  free_from_obligations: 'Свободно от обязательств',
  stadium: 'Стадион',
  current_stadium_value: 'Текущая стоимость стадиона (ВД)',
  training_base: 'Тренировочная база',
  current_base_value: 'Текущая стоимость базы (ВД)',
  youth_sports_school: 'ДЮСШ',
  current_school_value: 'Текущая стоимость школы (ВД)',
  total_infrastructure_amount: 'Общая стоимость недвижимости (ВД)',
  current_max_credit: 'Максимальная возможная сумма кредита (ВД)',
  current_credit: 'Текущая сумма долга (ВД)',
  daily_credit_increase: 'Ежедневный рост долга',
  percent_of_infrastructure: 'Процент долга от стоимости недвижимости',
  acceptance_of_the_condition:
    'Я принимаю условия кредита  и прошу выдать мне кредит в сумме (ВД)',
  get: 'Получить',
  enter_amount: 'введите сумму',
  return_credit: 'Вернуть кредит (ВД)',
  return: 'Вернуть',
  reject: 'Отклонить',
  confirm: 'Подтвердить',
  credit_confirm: `Вы уверены что хотите вять кредит на сумму (ВД) {amount}?`,
  credit_repay: `Вы уверены что хотите погасить кредит на сумму (ВД) {amount}?`,
  take_credit_success: 'Вы взяли кредит!',
  repay_credit_success: 'Вы вернули кредит!',
  current_training_base_value: 'Текущая стоимость тренировочной базы (ВД)',
  current_spa_value: 'Текущая стоимость СПА-центра (ВД)',
  current_medical_center_value:
    'Текущая стоимость реабилитационного центра (ВД)',
}
