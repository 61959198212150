export default {
  my_friends: 'Мои друзья',
  friends_requests: 'Заявки в друзья',
  my_friend: 'Мой друг',
  add_friend: 'Добавить в друзья',
  delete_from_friends: 'Удалить из друзей',
  friend_request_confirm: 'Запрос в друзья ожидает подтверждения',
  friends_from: 'Друзья {date}',
  request_created: 'Заявка от  {date}',
  was_online: 'Был(а) {interval} назад',
  online: 'Онлайн',
  friend_team_level: 'ур. - {level}',
  request_friend: 'Вы успешно отправили заявку в друзья',
  accept_friend: 'Вы успешно приняли приглашение менеджера',
  decline_friend: 'Вы успешно отклонили приглашение менеджера',
  cancel_friend: 'Вы успешно отменили предложение дружбы',
  delete_friend: 'Вы успешно прекратили дружбу',
  no_friends_yet: 'Пока нет друзей',
  no_incoming_requests: 'Нет входящих заявок',
  no_outgoing_requests: 'Нет исходящих заявок',
  incoming: 'Входящие',
  outgoing: 'Исходящие',
}
