export default {
  office: 'Офис',
  spa: 'СПА-центр',
  medical_center: 'Реабилитационный центр',
  training_base: 'Тренировочная база',
  field: 'Поле',
  gym: 'Тренажёрный зал',
  lighting: 'Освещение',
  scoreboard: 'Табло',
  bath: 'Баня',
  pool: 'Бассейн',
  salt_room: 'Соляная комната',
  sauna: 'Сауна',
  massage_room: 'Массажный кабинет',
  diagnostic_corps: 'Диагностический корпус',
  surgical_corps: 'Хирургический корпус',
  building_cost: 'Стоимость строения: ',
  is_activated: 'Активирован: ',
  activate: 'Активировать',
  activated: 'Активирован(а)',
  success_activate: 'Вы успешно активировали здание!',
  true: 'Да',
  false: 'Нет',
  objects: 'Объекты',
  tokens_for_last_day: 'За прошлые сутки мы заработали токенов: ',
  building_objects: 'Строящиеся объекты',
  type: 'Тип',
  object: 'Объект',
  stage: 'Стадия',
  duration: 'Длительность',
  speed_up_construction: 'Ускорить строительство',
  building: 'Идёт строительство...',
  build_today: 'Закончится сегодня',
  not_building: 'У вас сейчас не ведутся строительства',
  office_table: {
    full_name: 'Имя игрока',
    role: 'Позиция',
    age: 'Возраст',
    level: 'Уровень',
    parameters: 'Параметры',
    daily_parameter_changes: 'Прокачка за сегодня',
    physical_condition: 'Физический параметр',
    injury: 'Травма',
    train_players: 'Провести тренировку',
    train_players_success: 'Вы успешно провели тренировку игрока(ов)',
    will_relaxed: 'Будет восстановлено',
    restore_player: 'Восстановить игрока',
  },
  office_modal: {
    relax_player: 'Хотите восстановить физ кондиции игрока?',
    relax_players: 'Хотите восстановить физ кондиции игроков?',
    relax_cost: '{amount} единиц = 1 токен силы',
    restore: 'Восстановить',
    relaxed_player_success: 'Вы успешно восстановили физ состояние игрока(ов)',
    heal_player: 'Хотите ускорить реабилитацию игрока?',
    heal_cost: '1 единица = {amount} токен восстановления',
    speed_up: 'Ускорить',
    healed_player_success: 'Вы успешно восстановили травму игроку',
    not_enough_tokens: 'У вас не хватает токенов {tokenType}',
    go_to_shop: 'Перейти в магазин',
    speed_up_building: 'Хотите ускорить строительство? ',
    speed_up_cost: '{amount} день = 1 {token}',
    speed_up_building_success: 'Вы успешно ускорили строительство!',
  },
  offers: {
    sponsor_contract: 'Спонсорский контракт',
    sponsor_can_sign_new_sponsor:
      'У вас есть возможность подписать <span class="has-text-success">спонсорский</span> контракт до начала матчей в новом сезоне',
    sponsor_until_season_end:
      'Ваш <span class="has-text-success">спонсорский</span> контракт действителен до <span class="has-text-success">конца сезона</span>',
    sponsor_no_offers:
      '<span class="has-text-danger">Спонсорские</span> контракты доступны только в <span class="has-text-danger">начале сезона</span>',
    advertising_contract: 'Рекламный контракт',
    advertising_can_sign_new_ad:
      'У Вас есть возможность подписать новый <span class="has-text-success">рекламный</span> контракт',
    advertising_end_soon:
      'Ваш <span class="has-text-success">рекламный</span> контракт закончится через <span class="has-text-success is-uppercase">{closedAt}</span>',
    advertising_no_offers:
      'На данный момент у Вас нет <span class="has-text-danger">рекламных</span> предложений',
    sign: 'Подписать',
  },
}
