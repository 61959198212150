export default {
  games_today: 'игры сегодня',
  games_current: 'игры сейчас',
  best_players: 'лучшие игроки проекта',
  best_teams: 'лучшие команды проекта',
  sort_by: 'Сортировать по',
  attackers: 'нападающим',
  midfielders: 'полузащитникам',
  defenders: 'защитникам',
  goalkeepers: 'вратарям',
  news_button_text: 'Смотреть еще',
  season: 'Сезон: ',
  day: 'День: ',
  online: 'Онлайн: ',
  active_transfers: 'Игроков на трансфере: ',
  write: 'Написать',
  managers_online: 'Топ 50 менеджеров онлайн',
}
