import api from '@/http'

const state = () => ({
  league: null,
  team: null,
  newManagerLogin: '',
})

const getters = {}

const actions = {
  getLeaguesOccupancy() {
    return new Promise((resolve, reject) => {
      api
        .get('/leagues/occupancy')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTeams({ state }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/leagues/${state.league.id}/teams`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  takeTeam({ state }) {
    return new Promise((resolve, reject) => {
      api
        .post('teams/take', {
          team_id: state.team?.id,
          manager_login: state.newManagerLogin,
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  selectLeague(state, league) {
    state.league = league
  },
  selectTeam(state, team) {
    state.team = team
  },
  setNewManagerLogin(state, managerLogin) {
    state.newManagerLogin = managerLogin
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
