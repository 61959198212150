export default {
  upcoming_games_title: 'Предстоящие игры',
  finished_games_title: 'оконченные игры',
  upcoming_games_text:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
  choose_tactic: 'Выбрать тактику',
  choose_stadium: 'Выбрать стадион',
  home_icon_text: 'Домашняя игра',
  upcoming_games_empty: 'В этом сезоне у команды не будет игр',
  finished_games_empty: 'В этом сезоне у команды не было игр',
}
