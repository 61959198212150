export default {
  play: 'Играть',
  game_in_progress: 'Идет игра',
  tournament_in_progress: 'Идет турнир',
  logout: 'Выйти',
  team: 'Команда',
  transfers: 'Трансферы',
  finance: 'Финансы',
  infrastructure: 'Инфраструктура',
  stadium: 'Стадион',
  tournaments: 'Турниры',
  settings: 'Настройки',
  forum: 'Форум',
  transfer_list: 'Трансферный лист',
  bank: 'Банк ',
  financial_report: 'Фин. отчет',
  structure: 'Структура',
  club_base: 'База клуба',
  championships: 'Чемпионаты',
  cups: 'Кубки',
  world_cups: 'Мировые кубки',
  commercial_tournaments: 'Коммерческие турниры',
  upcoming_games: 'Предстоящие игры',
  finished_games: 'Оконченные игры',
  roster: 'Ростер',
  marketing: 'Маркетинг',
  friendly_game: 'Товарищеская',
  quick_tournament: 'Быстрый турнир',
  quick_tournament_in_queue: 'Вы в очереди на быстрый турнир',
  quick_tournament_out_queue: 'Покинуть очередь',
  in_queue: 'В очереди',
  office: 'Офис',
  search: 'Поиск',
  game_squad: 'Состав на игру',
  search_title: 'Поиск по сайту SHIFT+F',
  chat: 'Чат',
  chat_title: 'Открыть чат SHIFT+C',
}
