import api from '@/http'

const state = () => ({
  selectedTeamId: null,
  waiters: [],
  request: null,
})

const getters = {
  isEmptyWaiters: (state) => state.waiters.length === 0,
  isCurrentTeamInWaiters: (state, getters, rootState) =>
    state.waiters.some((waiter) => waiter.id === rootState.manager.team?.id),
  fromCurrentRequest: (state, getters, rootState) =>
    state.request?.from_id === rootState.manager.team?.id
      ? state.request
      : null,
  toCurrentRequest: (state, getters, rootState) =>
    state.request?.to_id === rootState.manager.team?.id ? state.request : null,
  sortedGameWaiters: (state, getters, rootState) =>
    state.waiters.sort((w1, w2) => {
      const w1Weight = Math.abs(w1.level - rootState.manager.team.level)
      const w2Weight = Math.abs(w2.level - rootState.manager.team.level)

      return w1Weight - w2Weight
    }),
}

const actions = {
  getWaiters({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .get('play-game-waiters', payload)
        .then(({ data }) => {
          commit('setWaiters', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getRequest({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('manager/team/play-game-request')
        .then(({ data }) => {
          if (data?.id) {
            commit('setRequest', data)
          }
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addToWaiters({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .post('manager/team/add-to-play-game-waiters')
        .then(({ data }) => {
          commit('addWaiter', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => commit('selectedTeamId', null))
    })
  },
  deleteFromWaiters({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .post('manager/team/delete-from-play-game-waiters')
        .then(({ data }) => {
          commit('removeWaiter', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendGameRequest({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .post('manager/team/play-game-request/send', {
          to_id: state.selectedTeamId,
        })
        .then(({ data, response }) => {
          commit('setRequest', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => commit('selectedTeamId', null))
    })
  },
  cancelGameRequest({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .post(`manager/team/play-game-request/${state.request.id}/cancel`, {
          display404ErrorMessage: true,
        })
        .then(({ data, response }) => {
          commit('setRequest', null)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  declineGameRequest({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .post(`manager/team/play-game-request/${state.request.id}/decline`, {
          display404ErrorMessage: true,
        })
        .then(({ data, response }) => {
          commit('setRequest', null)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  confirmGameRequest({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .post(`manager/team/play-game-request/${state.request.id}/confirm`, {
          display404ErrorMessage: true,
        })
        .then(({ data, response }) => {
          commit('setRequest', null)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.selectedTeamId = null
    state.waiters = []
    state.request = null
  },
  selectedTeamId(state, selectedTeamId) {
    state.selectedTeamId = selectedTeamId
  },
  setRequest(state, request) {
    state.request = request
  },
  setWaiters(state, waiters) {
    state.waiters = waiters
  },
  addWaiter(state, waiter) {
    state.waiters = state.waiters.filter(w => w.id !== waiter.id)
    state.waiters.push(waiter)
  },
  removeWaiter(state, waiter) {
    state.waiters = state.waiters.filter(w => w.waiter_id !== waiter.waiter_id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
