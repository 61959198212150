export default {
  dribble_centermagnet: 'Смещение в центр',
  dribble_offensiveness: 'Игра на атаку',
  position_defense_depth_factor: 'Игра в защиту',
  position_defense_width_factor: 'Ширина линии обороны',
  position_defense_sidefocus_strength: 'Игра по  флангам  в защите',
  position_defense_microfocus_strength: 'Прессинг на своей половине',
  position_defense_midfieldfocus: 'Фокус на оборону',
  position_offense_width_factor: 'Ширина линии атаки',
  position_offense_midfieldfocus: 'Фокус на атаку',
  position_offense_depth_factor: 'Глубина атаки',
  position_offense_microfocus_strength: 'Прессинг на половине соперника',
  position_offense_sidefocus_strength: 'Игра по флангам в атаке',
}
