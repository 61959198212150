export default {
  quick_tournament_title: 'Быстрые турниры',
  quick_tournament_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
  tournament_stage: 'Этап: {stage} тур',
  prize_prefix: 'Приз - ',
  prize_suffix: ' {number} токенов',
  tours: 'Туры',
  go_to_main: 'На главную',
  tournament_finished: 'Турнир завершен',
  tournament_winner: 'Победитель турнира',
  tournament_prize: 'Приз: {amount}',
  return_to_quick_tournament: 'Вернутся к быстрому турниру ?',
  previous_tour_results: 'Результаты предыдущих игр',
  quick_tournament_tour: 'Туры',
  group_table: {
    team_name: 'Название команды',
    played: 'И',
    won: 'В',
    drawn: 'Н',
    lost: 'П',
    goals_for: 'Заб.голы',
    goals_against: 'Пр.голы',
    points: 'Очки',
  },
  you_added_quick_tournament: 'Вы добавлены в очередь на быстрый турнир',
  you_left_quick_tournament: 'Вы покинули очередь на быстрый турнир',
  group_total_points: 'очк|о|а|ов',

}
