import store from '@/store'
const axios = require('axios')
import _ from 'lodash'
import router from '@/router'
import { ToastProgrammatic as Toast } from 'buefy'
import i18n from '@/i18n'
import { tooManyRequestsMessage } from '@/service/error_429_message'
import settings from '@/service/settings'

const instance = axios.create({
  baseURL: settings.apiUrl,
})
const HTTP_CODE_BANNED = 550
const HTTP_CODE_BLOCKED_IP = 560
const HTTP_CODE_MAINTENANCE = 503

instance.interceptors.request.use(function (config) {
  if (config.url.includes('storage')) {
    return config
  }
  if (store.state.manager.authToken) {
    config.headers['Authorization'] = `Bearer ${store.state.manager.authToken}`
  }
  // controller используется для остановки запросов на сервер, когда пользователь покинул вкладку
  // в данном месте используется для того чтобы во время возвращения пользователя его не перекидывало на страницу ошибки, а оставляло на текущем роуте
  const controller = new AbortController()
  if (store.state.managerOutOfThePageTooLong) {
    controller.abort()
  }
  return {
    ...config,
    signal: controller.signal,
  }
})

instance.interceptors.response.use(
  function (response) {
    return {
      data: response.data.data,
      response,
    }
  },
  function (error) {
    const response = _.get(error, 'response')
    const statusCode = _.get(response, 'status', 0)
    const retryAfter = parseInt(_.get(response, 'headers.retry-after', 0))
    const message = _.get(response, 'data.message')
    const notFoundMessage = error?.config?.display404ErrorMessage

    if (statusCode === 401) {
      store.dispatch('manager/updateManager', null)
      store.commit('manager/setAuthToken', null)
      router.push({ name: 'login' })
    } else if (statusCode === HTTP_CODE_BANNED) {
      if (store.state.manager.manager !== null) {
        store.dispatch('manager/logout').then(() => {
          if (router.currentRoute.name !== 'banned') {
            router.push({ name: 'banned', params: { message: message } })
          }
        })
      } else {
        if (router.currentRoute.name !== 'banned') {
          router.push({ name: 'banned', params: { message: message } })
        }
      }
    } else if (statusCode === HTTP_CODE_BLOCKED_IP) {
      store.dispatch('manager/updateManager', null)
      store.commit('manager/setAuthToken', null)
      store.commit('manager/setBlockedIp', true)
      router.push({ name: 'blocked' })
    } else if (statusCode === HTTP_CODE_MAINTENANCE) {
      if (router.currentRoute.name !== 'maintenance') {
        router.push({ name: 'maintenance' })
      }
    } else if (statusCode >= 500) {
      Toast.open({
        message: i18n.t('global.server_error_msg'),
        type: 'is-danger',
        duration: 6000,
      })
    } else if (statusCode === 0 && store.state.managerOnThePage) {
      if (router.currentRoute.name !== 'no-internet') {
        router.push({
          name: 'no-internet',
          params: { previousPage: router.currentRoute.name },
        })
      }
    } else if (statusCode === 400) {
      Toast.open({
        message: i18n.t('global.invalid_request'),
        type: 'is-danger',
        duration: 4000,
      })
    } else if (statusCode === 404) {
      if (notFoundMessage) {
        Toast.open({
          message: i18n.t('global.not_found'),
          type: 'is-danger',
          duration: 6000,
        })
      } else {
        router.push({ name: 'not-found' }).catch({})
      }
    } else if (statusCode === 403) {
      Toast.open({
        message: i18n.t('global.cannot_perform_action'),
        type: 'is-danger',
        duration: 4000,
      })
    } else if (statusCode === 429) {
      if (response.headers['global-rate-limiter']) {
        if (router.currentRoute.name !== 'too-many-requests') {
          router.push({
            name: 'too-many-requests',
            params: { retryAfter: retryAfter },
          })
        }
      } else {
        Toast.open({
          message: tooManyRequestsMessage(retryAfter),
          type: 'is-danger',
        })
      }
    }
    return Promise.reject(error)
  }
)

export default instance
