export default {
  wallet_title: 'Кошелёк',
  created_at: 'Дата',
  direction: 'Тип',
  description: 'Описание',
  amount: 'Сумма',
  show_tokens: 'Показать все токены',
  hide_tokens: 'Скрыть',
  shop: 'Магазин',
}
