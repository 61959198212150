import api from '@/http'

const state = () => ({
  stadium: null,
})

const getters = {}

const actions = {
  getStadium({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('manager/team/stadium')
        .then(({ data }) => {
          commit('setStadium', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateStadiumName({ state, commit }, stadiumName) {
    return new Promise((resolve, reject) => {
      api
        .post(`/stadium/${state.stadium.id}/update-name`, stadiumName)
        .then(({ data }) => {
          commit('stadium/updateStadiumName', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  buildStadiumPlots({ state, commit }, plots) {
    return new Promise((resolve, reject) => {
      api
        .post(`/stadium/${state.stadium.id}/buy-plots`, { plots })
        .then(({ data }) => {
          commit('buildStadiumPlots', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  buildStadiumSector({ state, commit }, sektorKey) {
    return new Promise((resolve, reject) => {
      api
        .post(`/stadium/${state.stadium.id}/sectors/${sektorKey}/buy`)
        .then(({ data }) => {
          commit('buildStadiumSector', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  buildStadiumObject({ state, commit }, objectType) {
    return new Promise((resolve, reject) => {
      api
        .post(`/stadium/${state.stadium.id}/objects/${objectType}/buy`)
        .then(({ data }) => {
          commit('buildStadiumObject', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  buildStadiumPlaces(ctx, data) {
    return new Promise((resolve, reject) => {
      api
        .post(`/stadium-sectors/${data.sectorId}/build-places`, data.payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.stadium = null
  },
  setStadium(state, stadium) {
    state.stadium = stadium
  },
  updateStadiumName(state, stadiumName) {
    state.stadium.name = stadiumName
  },
  buildStadiumPlots(state, plots) {
    state.stadium.free_plots = plots
  },
  buildStadiumSector(state, sector) {
    state.stadium.sectors[sector.key].sector = sector
  },
  buildStadiumObject(state, object) {
    state.stadium.objects[object.type].object = object
  },
  buildStadiumPlaces(state, places) {
    state.stadium.places[places.type] = places
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
