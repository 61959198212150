/* eslint-disable */
import api from '@/http'
import VueCookies from 'vue-cookies'
import Ws from '@/service/ws'
import Moment from 'moment-timezone'

const state = () => ({
  manager: null,
  authToken: VueCookies.get('__token'),
  team: null,
  closestGames: [],
  currentGame: null,
  tokens: null,
  quickTournament: null,
  activeQuickTournamentId: null,
  blockedIp: VueCookies.get('__blockedIp'),
})

const getters = {
  canCreateMessage: (state) => {
    if (state.manager?.mut_until) {
      const mutUntil = Moment(state.manager?.mut_until)
      return mutUntil < Moment()
    }

    return true
  },
  hasManagerEnoughTokens: (state) => (tokenType, tokenCount) => {
    return state.tokens[tokenType] >= tokenCount
  },
  hasRoleModerator: (state) => state.manager.role === 'Moderator',
}

const actions = {
  getClosestGames({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('manager/team/closest-games')
        .then(({ data }) => {
          commit('setClosestGames', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('login', payload)
        .then(({ data }) => {
          commit('setAuthToken', data.token)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  socialLogin({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .post(`login/social/${data.provider}`, data.payload)
        .then(({ data }) => {
          commit('setAuthToken', data.token)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  register({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('register', payload)
        .then(({ data }) => {
          commit('setAuthToken', data.token)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      api
        .post('logout')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('updateManager', null)
          commit('setAuthToken', null)
        })
    })
  },
  forgotPassword(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('forgot-password', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  processForgotPassword(ctx, { url, payload }) {
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendVerifyEmail() {
    return new Promise((resolve, reject) => {
      api
        .post('email/send-verify')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verifyEmail(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('email/verify', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getManager({ state, dispatch }, force = false) {
    return new Promise((resolve, reject) => {
      if (!force) {
        if (state.manager !== null) {
          resolve(state.manager)
          return
        }
      }

      if (state.authToken) {
        api
          .get('profile')
          .then(({ data }) => {
            dispatch('updateManager', data)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        resolve(null)
      }
    })
  },
  updateManager({ state, commit }, manager) {
    if (manager === null) {
      commit('setManager', null)
      commit('setTeam', null)
      commit('setClosestGames', [])
      commit('setCurrentGame', null)
      commit('setManagerTokens', null)
      commit('setQuickTournament', null)
      commit('setActiveQuickTournamentId', null)
      Ws.getInstance().disconnect()
    } else {
      const prevManagerId = state.manager?.id
      commit('setManager', manager)
      commit('setTeam', manager.team)

      if (!prevManagerId) {
        Ws.getInstance().connect()
      } else if (prevManagerId !== manager.id) {
        window.location.reload()
      }
    }
  },
  getManagerTokenTransactions(ctx, params) {
    return new Promise((resolve, reject) => {
      api
        .get('/manager-token-transactions', { params })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getManagerStatus({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/profile/status')
        .then(({ data, response }) => {
          commit('setManagerTokens', data.tokens_balance)
          commit('setQuickTournament', data.quick_tournament_registration_stats)
          commit('setActiveQuickTournamentId', data.active_quick_tournament_id)
          commit('setClosestGames', data.closest_games)
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setManager(state, manager) {
    state.manager = manager
  },
  setTeam(state, team) {
    state.team = team
  },
  setCurrentGame(state, game) {
    state.currentGame = game
  },
  setClosestGames(state, games) {
    state.closestGames = games
  },
  addClosestGame(state, game) {
    state.closestGames.push(game)
  },
  removeClosestGame(state, game) {
    state.closestGames = state.closestGames.filter((g) => g.id !== game.id)
  },
  updateManagerHasUnreadMessages(state, hasUnreadMessages) {
    if (state.manager) {
      state.manager.has_unread_messages = hasUnreadMessages
    }
  },
  deleteOwnTeamImage(state) {
    state.team.own_image_url = null
  },
  setAuthToken(state, token) {
    state.authToken = token
    const currentHost = window.location.hostname

    if (!token) {
      VueCookies.remove('__token')
      VueCookies.remove('__token', null, `.${currentHost}`)
    } else {
      VueCookies.set('__token', token, -1, null, `.${currentHost}`)
    }
  },
  setManagerTokens(state, tokens) {
    state.tokens = tokens
  },
  setQuickTournament(state, quickTournament) {
    state.quickTournament = quickTournament
  },
  setActiveQuickTournamentId(state, id) {
    state.activeQuickTournamentId = id
  },
  setBlockedIp(state, blockedIp) {
    state.blockedIp = blockedIp
    const currentHost = window.location.hostname

    VueCookies.set('__blockedIp', blockedIp, -1, null, `.${currentHost}`)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
