import Moment from 'moment-timezone'
import i18n from '@/i18n'
import { formatWordDeclination } from '@/service/word_declination'

export function tooManyRequestsMessage(timeDuration) {
  const duration = Moment.duration(timeDuration, 'seconds')
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  const message = i18n.t('global.too_many_requests', {
    hours: hours > 0 ? timeTypeText(hours, 'hours') : '',
    minutes: minutes > 0 ? timeTypeText(minutes, 'minutes') : '',
    seconds: timeTypeText(seconds, 'seconds'),
  })
  return message
}

export function timeTypeText(time, timeType) {
  return time + ' ' + formatWordDeclination(time, i18n.t(`global.${timeType}`))
}
