export default {
  server_error_msg:
    'Похоже, что на сервере произошла ошибка. Попробуйте перезагрузить страницу',
  internet_error:
    'Проблемы при подключении к серверу. Проверьте интернет-соединение',
  invalid_request: 'Неверный запрос',
  cannot_perform_action: 'Вы не можете выполнить это действие',
  too_many_requests:
    'Слишком много запросов. Пожалуйста, попробуйте повторить через {hours} {minutes} {seconds}',
  ws_connect_error:
    'Не удается подключится к сокет-серверу. Некоторые функции могут не работать',
  forum: 'Форум',
  create_your_team: 'Создайте свою первую команду',
  create_team_total_information: 'Общая информация',
  create_team_total_name: 'Название',
  create_team_total_emblem: 'Эмблема',
  create_team_total_country: 'Страна',
  create_team_total_summary: 'Сводка',
  create_team_total_next: 'Далее',
  create_team_total_prev: 'Назад',
  create_team_enter_name: 'Введите название команды',
  create_team_enter_manager_login: 'Введите Ваш логин',
  create_team_choose_emblem: 'Выберите эмблему',
  create_team_choose_country: 'Выберите страну',
  create_team_name_team: 'Название',
  create_team_confirm_button: 'Создать команду',
  create_team_created_successfully: 'Ваша команда была успешно создана!',
  occupied_places: 'Количество занятых мест / всего команд в чемпионате',
  login: 'Логин',
  tournaments: 'Турниры',
  save: 'Сохранить',
  close: 'Закрыть',
  changes_saved_successfully: 'Изменения были успешно сохранены',
  buy: 'Купить',
  upload: 'Загрузить',
  delete: 'Удалить',
  send: 'Отправить',
  cancel: 'Отменить',
  seasons: 'Сезоны',
  search: 'Поиск',
  today: 'Сегодня',
  yesterday: 'Вчера',
  log_in_with: 'Войти с помощью: {company}',
  modal_cancel: 'Отмена',
  copy_success: 'Скопировано',
  copy_error: 'Ошибка копирования',
  notification_link: 'Перейти',
  accept: 'Принять',
  reject: 'Отклонить',
  not_found: 'Ресурс не был найден',
  no_notifications: 'Пока что у Вас не было уведомлений',
  seconds: 'секун|ду|ды|д',
  minutes: 'мину|ту|ты|т',
  hours: 'ча|с|са|сов',
  purchase: 'Покупка',
  pay: 'Оплатить',
  audio_error: 'Ошибка воспроизведения звука:',
  use: 'Использовать',
  thousand: 'тыс',
  million: 'млн',
  billion: 'млрд',
  manager_out: 'Мы заметили что Вы отсутствовали продолжительное время',
  return_to_site: 'Вернуться на сайт?',
  yes: 'Да',
}
