import api from '@/http'

const state = () => ({})

const getters = {}

const actions = {
  getStatisicData() {
    return new Promise((resolve, reject) => {
      api
        .get('statistic/main-stats')
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getManagersOnline() {
    return new Promise((resolve, reject) => {
      api
        .get('statistic/managers-online')
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
