import api from '@/http'
import _ from 'lodash'
import Moment from 'moment-timezone'

const state = () => ({
  selectedRoomId: null,
  messages: [],
  rooms: [],
  managers: [],
  chatWidgetActive: false,
})

const getters = {
  messagesByDay: (state) => {
    const groupedMessages = _.groupBy(state.messages, (m) =>
      Moment(m.created_at).format('YYYY-MM-DD')
    )
    const data = []
    for (const date in groupedMessages) {
      data.push({
        date: Moment(date),
        messages: groupedMessages[date],
      })
    }

    return data
  },
  currentRoomName: (state) => {
    if (state.rooms && state.selectedRoomId) {
      return state.rooms.find((room) => room.id === state.selectedRoomId)?.name
    }
    return null
  },
}

const actions = {
  getRooms({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('chat/rooms')
        .then(({ data, response }) => {
          commit('setRooms', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMessages({ state, commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`chat/rooms/${state.selectedRoomId}/messages`)
        .then(({ data, response }) => {
          commit('setMessages', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createNewMessage({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`chat/rooms/${state.selectedRoomId}/messages`, payload)
        .then(({ data, response }) => {
          dispatch('tryToAddNewMessage', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteMessage({ state, commit }, messageId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`chat/rooms/${state.selectedRoomId}/messages/${messageId}`)
        .then(({ data, response }) => {
          commit('deleteMessageById', messageId)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  tryToAddNewMessage({ state, commit }, message) {
    if (!state.messages.some((m) => m.id === message.id)) {
      const messages = [message].concat(state.messages)
      commit('setMessages', messages)
    }
  },
}

const mutations = {
  resetState(state) {
    state.selectedRoomId = null
    state.messages = []
    state.rooms = null
    state.managers = []
  },
  setManagers(state, managers) {
    state.managers = managers
  },
  setRooms(state, rooms) {
    state.rooms = rooms
  },
  setMessages(state, messages) {
    state.messages = messages
  },
  setSelectedRoomId(state, roomId) {
    state.selectedRoomId = roomId
  },
  deleteMessageById(state, messageId) {
    state.messages = state.messages.filter((m) => m.id !== messageId)
  },
  setChatWidgetActive(state, chatWidgetActive) {
    state.chatWidgetActive = chatWidgetActive
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
