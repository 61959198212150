import api from '@/http'

const state = () => ({
  officeBuilding: null,
  players: [],
  info: null,
})

const getters = {}

const actions = {
  getOfficeBuilding({ commit }, buildingType) {
    return new Promise((resolve, reject) => {
      api
        .get(`/manager/team/office/buildings/${buildingType}`)
        .then(({ data }) => {
          commit('setOfficeBuilding', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPlayers({ commit }, buildingId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/manager/team/office/buildings/${buildingId}/players`)
        .then(({ data }) => {
          commit('setPlayers', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/manager/team/office/info')
        .then(({ data }) => {
          commit('setOfficeInfo', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  buyOfficePlots({ commit }, { buildingId, plots }) {
    return new Promise((resolve, reject) => {
      api
        .post(`/manager/team/office/buildings/${buildingId}/buy-plots`, {
          plots,
        })
        .then(({ data }) => {
          commit('buyOfficePlots', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  buildOfficeObject(ctx, { buildingId, objectType }) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/manager/team/office/buildings/${buildingId}/objects/${objectType}/build`
        )
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activateOfficeBuilding(ctx, buildingId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/manager/team/office/buildings/${buildingId}/activate`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  healPlayer(ctx, { buildingId, playerId, tokens }) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/manager/team/office/buildings/${buildingId}/players/${playerId}/heal`,
          { tokens }
        )
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  relaxPlayer(ctx, { buildingId, playerId, tokens }) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/manager/team/office/buildings/${buildingId}/players/${playerId}/relax`,
          { tokens }
        )
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  trainPlayers(ctx, { buildingId, playerIds }) {
    return new Promise((resolve, reject) => {
      api
        .post(`/manager/team/office/buildings/${buildingId}/players/train`, {
          player_ids: playerIds,
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  speedupBuilding(ctx, { objectId, tokens }) {
    return new Promise((resolve, reject) => {
      api
        .post(`/manager/team/office/building-objects/${objectId}/speed-up`, {
          tokens,
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  relaxPlayersUntilFull(ctx, { buildingId, playerIds }) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/manager/team/office/buildings/${buildingId}/players/relax-until-full`,
          {
            player_ids: playerIds,
          }
        )
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.officeBuilding = null
    state.players = []
    state.info = null
  },
  setOfficeBuilding(state, officeBuilding) {
    state.officeBuilding = officeBuilding
  },
  buyOfficePlots(state, plots) {
    state.officeBuilding.free_plots = plots
  },
  setPlayers(state, players) {
    state.players = players
  },
  setOfficeInfo(state, info) {
    state.info = info
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
