export default {
  email_verification: 'Верификация e-mail',
  registration_email:
    'На указанный при регистации E-mail будет отправлен код верификации',
  code_from_email: 'Введите код, который был отправлен на ваш E-mail',
  get_code: 'Получить код',
  no_code: 'Не получили код? Отправить снова',
  has_code: 'У меня уже есть код-подтверждение',
  verification_success: 'Вы успешно верифицировали свой e-mail!',
  verification_code_send: 'Код верификации отправлен на ваш e-mail!',
}
