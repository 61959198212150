export const authRoutes = [
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: "LogoutView" */ '@/views/LogoutView'),
  },
  {
    path: '/',
    name: 'main',
    component: () =>
      import(/* webpackChunkName: "MainView" */ '@/views/MainView'),
  },
  {
    path: '/managers-online',
    name: 'managers-online',
    component: () =>
      import(
        /* webpackChunkName: "ManagersOnlineView" */ '@/views/ManagersOnlineView'
      ),
  },
  {
    path: '/team-template',
    name: 'team-template',
    component: () =>
      import(
        /* webpackChunkName: "TeamTemplate" */ '@/views/Game/TeamTemplate'
      ),
  },
  {
    path: '/team/:id/roster',
    name: 'team-roster',
    component: () =>
      import(
        /* webpackChunkName: "TeamRoster" */ '@/views/Team/TeamRosterView'
      ),
  },
  {
    path: '/my-team/roster',
    name: 'my-team-roster',
    component: () =>
      import(
        /* webpackChunkName: "TeamRoster" */ '@/views/Team/TeamRosterView'
      ),
  },
  {
    path: '/play-game',
    name: 'play-game',
    component: () =>
      import(/* webpackChunkName: "PlayGame" */ '@/views/Game/PlayGame'),
  },
  {
    path: '/mail',
    name: 'mail',
    component: () =>
      import(/* webpackChunkName: "MailView" */ '@/views/Mail/MailView'),
    children: [
      {
        path: 'conversations/:id',
        name: 'mail-conversation',
        component: () =>
          import(
            /* webpackChunkName: "MailConversationView" */ '@/views/Mail/MailConversationView'
          ),
      },
    ],
  },
  {
    path: '/game/:id',
    name: 'game',
    component: () =>
      import(/* webpackChunkName: "Game" */ '@/views/Game/GameView'),
  },
  {
    path: '/game/:id/template',
    name: 'game-template',
    component: () =>
      import(
        /* webpackChunkName: "GameTemplate" */ '@/views/Game/GameTemplate'
      ),
  },
  {
    path: '/upcoming-games',
    name: 'upcoming-games',
    component: () =>
      import(
        /* webpackChunkName: "UpcomingGames" */ '@/views/Game/UpcomingGames'
      ),
  },
  {
    path: '/finished-games',
    name: 'finished-games',
    component: () =>
      import(
        /* webpackChunkName: "FinishedGames" */ '@/views/Game/FinishedGames'
      ),
  },
  {
    path: '/games/:id/available-stadiums',
    name: 'available-stadiums',
    component: () =>
      import(
        /* webpackChunkName: "AvailableStadiumsView" */ '@/views/Game/AvailableStadiumsView'
      ),
  },
  {
    path: '/create-team',
    name: 'create-team',
    component: () =>
      import(
        /* webpackChunkName: "CreateTeamView" */ '@/views/Team/CreateTeamView'
      ),
  },
  {
    path: '/take-team',
    name: 'take-team',
    component: () =>
      import(
        /* webpackChunkName: "TakeTeamView" */ '@/views/Team/TakeTeamView'
      ),
  },
  {
    path: '/players/:id',
    name: 'player',
    component: () =>
      import(
        /* webpackChunkName: "DetailedPlayerView" */ '@/views/Player/DetailedPlayerView'
      ),
  },
  {
    path: '/tournaments',
    name: 'tournaments',
    component: () =>
      import(
        /* webpackChunkName: "TournamentsView" */ '@/views/Tournament/TournamentsView'
      ),
    children: [
      {
        path: 'national-championships',
        name: 'national-championships',
        component: () =>
          import(
            /* webpackChunkName: "NationalChampionshipsView" */ '@/views/Tournament/NationalChampionshipsView'
          ),
        alias: '/',
      },
      {
        path: 'national-cups',
        name: 'national-cups',
        component: () =>
          import(
            /* webpackChunkName: "NationalCupsView" */ '@/views/Tournament/NationalCupsView'
          ),
      },
      {
        path: ':id',
        name: 'tournament',
        component: () =>
          import(
            /* webpackChunkName: "DetailedTournamentView" */ '@/views/Tournament/DetailedTournamentView'
          ),
        children: [
          {
            path: 'tours/:tourNumber',
            name: 'tournament-tours',
            component: () =>
              import(
                /* webpackChunkName: "ToursModalView" */ '@/views/Tournament/ToursModalView'
              ),
          },
        ],
      },
      {
        path: ':id/statistic',
        name: 'tournament-statistic',
        component: () =>
          import(
            /* webpackChunkName: "TournamentStatisticView" */ '@/views/Tournament/TournamentStatisticView'
          ),
        children: [
          {
            path: 'teams',
            name: 'tournament-teams',
            alias: '/',
            component: () =>
              import(
                /* webpackChunkName: "TournamentStatisticTeamsView" */ '@/views/Tournament/TournamentStatisticTeamsView'
              ),
          },
          {
            path: 'best-players',
            name: 'tournament-best-players',
            component: () =>
              import(
                /* webpackChunkName: "TournamentStatisticBestPlayersView" */ '@/views/Tournament/TournamentStatisticBestPlayersView'
              ),
          },
          {
            path: 'teams/infrastructure',
            name: 'tournament-infrastructure',
            component: () =>
              import(
                /* webpackChunkName: "TournamentStatisticInfrastructureView" */ '@/views/Tournament/TournamentStatisticInfrastructureView'
              ),
          },
          {
            path: 'finished-transfers',
            name: 'tournament-finished-transfers',
            component: () =>
              import(
                /* webpackChunkName: "TournamentStatisticFinishedTransfersView" */ '@/views/Tournament/TournamentStatisticFinishedTransfersView'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/managers/:login',
    component: () =>
      import(
        /* webpackChunkName: "ManagerView" */ '@/views/Manager/ManagerView'
      ),
    children: [
      {
        path: '/',
        name: 'manager',
        component: () =>
          import(
            /* webpackChunkName: "ProfileManagerView" */ '@/views/Profile/ProfileManagerView'
          ),
      },
      {
        path: 'friends',
        name: 'friends',
        component: () =>
          import(
            /* webpackChunkName: "ManagerFriendsView" */ '@/views/Manager/ManagerFriendsView'
          ),
      },
    ],
  },
  {
    path: '/profile',
    component: () =>
      import(
        /* webpackChunkName: "ProfileView" */ '@/views/Profile/ProfileView'
      ),
    children: [
      {
        path: '/',
        name: 'profile_manager',
        component: () =>
          import(
            /* webpackChunkName: "ProfileManagerView" */ '@/views/Profile/ProfileManagerView'
          ),
      },
      {
        path: 'friends',
        component: () =>
          import(
            /* webpackChunkName: "ProfileFriendsView" */ '@/views/Profile/ProfileFriendsView'
          ),
        children: [
          {
            path: '/',
            name: 'profile_friends',
            component: () =>
              import(
                /* webpackChunkName: "ManagerFriendsView" */ '@/views/Manager/ManagerFriendsView'
              ),
          },
          {
            path: 'requests',
            component: () =>
              import(
                /* webpackChunkName: "ProfileRequestsView" */ '@/views/Profile/ProfileRequestsView'
              ),
            children: [
              {
                path: 'incoming',
                name: 'incoming',
                alias: '/',
                component: () =>
                  import(
                    /* webpackChunkName: "IncomingFriendsRequestsView" */ '@/views/Friends/IncomingFriendsRequestsView'
                  ),
              },
              {
                path: 'outgoing',
                name: 'outgoing',
                component: () =>
                  import(
                    /* webpackChunkName: "OutgoingFriendsRequestsView" */ '@/views/Friends/OutgoingFriendsRequestsView'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'referrals',
        name: 'profile_referrals',
        component: () =>
          import(
            /* webpackChunkName: "ProfileReferralView" */ '@/views/Profile/ProfileReferralView'
          ),
      },
    ],
  },
  {
    path: '/bank',
    name: 'bank',
    component: () =>
      import(/* webpackChunkName: "BankView" */ '@/views/Finance/BankView'),
  },
  {
    path: '/vd-report',
    name: 'vd-report',
    component: () =>
      import(
        /* webpackChunkName: "VdReportView" */ '@/views/Finance/VdReportView'
      ),
  },
  {
    path: '/settings',
    name: 'settings',
    redirect: '/settings/general',
    component: () =>
      import(
        /* webpackChunkName: "SettingsView" */ '@/views/Settings/SettingsView'
      ),
    children: [
      {
        path: 'general',
        name: 'settings-general',
        component: () =>
          import(
            /* webpackChunkName: "SettingsGeneralView" */ '@/views/Settings/SettingsGeneralView'
          ),
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: () =>
          import(
            /* webpackChunkName: "ChangePasswordView" */ '@/views/Settings/ChangePasswordView'
          ),
      },
      {
        path: 'change-email',
        name: 'change-email',
        component: () =>
          import(
            /* webpackChunkName: "ChangeEmailView" */ '@/views/Settings/ChangeEmailView'
          ),
      },
    ],
  },
  {
    path: '/transfers',
    name: 'transfers',
    component: () =>
      import(
        /* webpackChunkName: "TransfersView" */ '@/views/Transfer/TransfersView'
      ),
    children: [
      {
        path: 'transfer-list',
        name: 'transfer-list',
        component: () =>
          import(
            /* webpackChunkName: "TransferListView" */ '@/views/Transfer/TransferListView'
          ),
      },
      {
        path: 'negotiation',
        name: 'negotiation',
        component: () =>
          import(
            /* webpackChunkName: "NegotiationView" */ '@/views/Transfer/NegotiationView'
          ),
      },
      {
        path: 'my-team',
        name: 'my-team',
        component: () =>
          import(
            /* webpackChunkName: "MyTeamView" */ '@/views/Transfer/MyTeamView'
          ),
      },
      {
        path: 'direct-transfers',
        name: 'direct-transfers',
        component: () =>
          import(
            /* webpackChunkName: "DirectTransfersView" */ '@/views/Transfer/DirectTransfersView'
          ),
        children: [
          {
            path: 'team-request',
            name: 'transfer-team-request',
            component: () =>
              import(
                /* webpackChunkName: "TeamRequestTemplate" */ '@/components/Transfer/TeamRequestTemplate'
              ),
          },
          {
            path: 'team-offers',
            name: 'transfer-team-offers',
            component: () =>
              import(
                /* webpackChunkName: "TeamOffersTemplate" */ '@/components/Transfer/TeamOffersTemplate'
              ),
          },
        ],
      },
      {
        path: 'closed-transfers',
        name: 'closed-transfers',
        component: () =>
          import(
            /* webpackChunkName: "ClosedTransfersView" */ '@/views/Transfer/ClosedTransfersView'
          ),
      },
    ],
  },
  {
    path: '/stadium',
    name: 'stadium',
    component: () =>
      import(
        /* webpackChunkName: "StadiumView" */ '@/views/Stadium/StadiumView'
      ),
    props: true,
    children: [
      {
        path: 'object/:objectType',
        name: 'stadium-object',
        component: () =>
          import(
            /* webpackChunkName: "BuildStadiumObjectModalView" */ '@/views/Stadium/BuildStadiumObjectModalView'
          ),
      },
      {
        path: 'sector/:sectorKey',
        name: 'stadium-sector',
        component: () =>
          import(
            /* webpackChunkName: "BuildStadiumSectorModalView" */ '@/views/Stadium/BuildStadiumSectorModalView'
          ),
      },
    ],
  },
  {
    path: '/send-verify',
    name: 'send-verify',
    component: () =>
      import(
        /* webpackChunkName: "SendVerifyModalView" */ '@/views/SendVerifyModalView'
      ),
  },
  {
    path: '/verify',
    name: 'verify',
    component: () =>
      import(
        /* webpackChunkName: "VerifyModalView" */ '@/views/VerifyModalView'
      ),
  },
  {
    path: '/marketing',
    component: () =>
      import(
        /* webpackChunkName: "MarketingView" */ '@/views/Marketing/MarketingView'
      ),
    children: [
      {
        path: 'shop',
        name: 'shop',
        alias: '/',
        component: () =>
          import(
            /* webpackChunkName: "ShopView" */ '@/views/Marketing/ShopView'
          ),
      },
      {
        path: 'sponsors',
        name: 'sponsors',
        component: () =>
          import(
            /* webpackChunkName: "SponsorsView" */ '@/views/Marketing/SponsorsView'
          ),
        children: [
          {
            path: 'contracts',
            name: 'sponsors-contracts',
            component: () =>
              import(
                /* webpackChunkName: "SignedContranctsList" */ '@/components/Marketing/SignedContranctsList.vue'
              ),
            meta: { tab: 'sponsors' },
          },
          {
            path: 'offers',
            name: 'sponsors-offers',
            component: () =>
              import(
                /* webpackChunkName: "AvailableSponsorsList" */ '@/components/Marketing/AvailableSponsorsList.vue'
              ),
            meta: { tab: 'sponsors' },
          },
        ],
      },
      {
        path: 'advertising',
        name: 'advertising',
        component: () =>
          import(
            /* webpackChunkName: "AdvertisingView" */ '@/views/Marketing/AdvertisingView'
          ),
        children: [
          {
            path: 'contracts',
            name: 'advertising-contracts',
            component: () =>
              import(
                /* webpackChunkName: "SignedAdvertisingContracts" */ '@/components/Marketing/SignedAdvertisingContracts.vue'
              ),
            meta: { tab: 'advertising' },
          },
          {
            path: 'offers',
            name: 'advertising-offers',
            component: () =>
              import(
                /* webpackChunkName: "AvailableAdvertisingContracts" */ '@/components/Marketing/AvailableAdvertisingContracts.vue'
              ),
            meta: { tab: 'advertising' },
          },
        ],
      },
    ],
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () =>
      import(
        /* webpackChunkName: "UserWalletView" */ '@/views/Wallet/UserWalletView'
      ),
  },
  {
    path: '/quick-tournament/:id',
    name: 'quick-tournament',
    component: () =>
      import(
        /* webpackChunkName: "DetailedPlayerView" */ '@/views/Tournament/QuickTournamentView'
      ),
    children: [
      {
        path: 'tours/:tourNumber',
        name: 'quick-tournament-tours',
        component: () =>
          import(
            /* webpackChunkName: "ToursModalView" */ '@/views/Tournament/ToursModalView'
          ),
      },
    ],
  },
  {
    path: '/office',
    component: () =>
      import(
        /* webpackChunkName: "OfficeRouteView" */ '@/views/Office/OfficeRouteView'
      ),
    children: [
      {
        path: '/',
        name: 'office',
        component: () =>
          import(
            /* webpackChunkName: "OfficeView" */ '@/views/Office/OfficeView'
          ),
      },
      {
        path: 'buildings/:buildingType',
        name: 'building',
        component: () =>
          import(
            /* webpackChunkName: "BuildingView" */ '@/views/Office/BuildingView'
          ),
        children: [
          {
            path: 'object/:objectType',
            name: 'office-object',
            component: () =>
              import(
                /* webpackChunkName: "BuildOfficeObjectModalView" */ '@/views/Office/BuildOfficeObjectModalView'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/shop',
    name: 'project-shop',
    component: () =>
      import(
        /* webpackChunkName: "ProjectShopView" */ '@/views/Shop/ProjectShopView'
      ),
  },
  {
    path: '/real-payments/:exchangePackageId/providers',
    name: 'real-payments',
    component: () =>
      import(
        /* webpackChunkName: "ExchangePackageByRealPaymentView" */ '@/views/Shop/ExchangePackageByRealPaymentView'
      ),
  },
  {
    path: '/payment/success',
    name: 'payment-success',
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccessView" */ '@/views/Shop/PaymentSuccessView'
      ),
  },
  {
    path: '/payment/error',
    name: 'payment-error',
    component: () =>
      import(
        /* webpackChunkName: "PaymentErrorView" */ '@/views/Shop/PaymentErrorView'
      ),
  },
]

export function markAsAuth(routes) {
  for (const route of routes) {
    route.requireAuth = true
    if (Array.isArray(route.children)) {
      markAsAuth(route.children)
    }
  }
}
