import tacticPositions from './tactic_positions'
import tacticTeamSettings from './tactic_team_settings'
import { CoordsConverter } from './coords_converter'

class Game {
  constructor() {
    this.tacticPositions = tacticPositions
    this.tacticTeamSettings = tacticTeamSettings
    this.coordsConverter = new CoordsConverter()
  }
  gameAbleTacticPositions() {
    return this.tacticPositions.filter((pos) => !pos.reserve)
  }
  reserveTacticPositions() {
    return this.tacticPositions.filter((pos) => pos.reserve)
  }
  tacticAttackSettings() {
    return [
      'dribble_centermagnet',
      'dribble_offensiveness',
      'position_offense_width_factor',
      'position_offense_depth_factor',
    ]
  }
  tacticDefenseSettings() {
    return ['position_defense_depth_factor', 'position_defense_width_factor']
  }
  tacticPressureSettings() {
    return [
      'position_offense_midfieldfocus',
      'position_defense_sidefocus_strength',
      'position_defense_microfocus_strength',
      'position_defense_midfieldfocus',
      'position_offense_microfocus_strength',
      'position_offense_sidefocus_strength',
    ]
  }
  static isReservePosition(pos) {
    return Boolean(tacticPositions.find((tp) => tp.pos === pos).reserve)
  }
}

export { Game }
