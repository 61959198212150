import _ from 'lodash'

export default {
  methods: {
    getStatusCode: function (error) {
      return _.get(error, 'response.status', null)
    },
    getValidationErrorMessage(error) {
      return _.get(error, 'response.data.message', 'error')
    },
    displayValidationErrorMessage(error) {
      if (
        this.getStatusCode(error) === 422 &&
        this.getValidationErrorMessage(error)
      ) {
        this.$buefy.toast.open({
          message: this.getValidationErrorMessage(error),
          type: 'is-danger',
          duration: 6000,
        })
      }
    },
  },
}
