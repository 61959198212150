import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { authRoutes, markAsAuth } from './auth_routes'
import guestRoutes from './guest_routes'
import generalRoutes from './general_routes'
import i18n from '@/i18n'
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from '@/service/local_storage'
import Moment from 'moment-timezone'
import VueNprogress from 'vue-nprogress'

Vue.use(VueNprogress)
Vue.use(VueRouter)

markAsAuth(authRoutes)

const nprogress = new VueNprogress({
  showSpinner: false,
})

const routes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "GlobalPageWrapperView" */ '@/views/GlobalPageWrapperView'
      ),
    children: [
      {
        path: '/main',
        component: () =>
          import(
            /* webpackChunkName: "AuthContentView" */ '@/views/AuthContentView'
          ),
        meta: { requireAuth: true },
        children: authRoutes,
      },
      {
        path: '/',
        name: 'landing',
        component: () =>
          import(/* webpackChunkName: "LandingView" */ '@/views/LandingView'),
        children: guestRoutes,
      },
      ...generalRoutes,
    ],
  },
  {
    path: '/blocked',
    name: 'blocked',
    component: () =>
      import(
        /* webpackChunkName: "ManagerBlokedIpView" */ '@/views/Errors/ManagerBlokedIpView'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (store.state.manager.blockedIp) {
    if (to.name === 'blocked') {
      next()
    } else {
      next({ name: 'blocked' })
    }
  }
  const errorRoutes = ['maintenance', 'too-many-requests', 'no-internet']
  let manager = !errorRoutes.includes(to.name)
    ? await store.dispatch('manager/getManager')
    : null
  if (manager !== null && to.name === 'login' && to.query.token) {
    try {
      await store.dispatch('manager/logout')
    } catch (err) {
      console.error(err)
    }
  }
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (manager === null) {
      next({ name: 'login' })
      return
    }
    if (
      store.state.manager.manager.should_verify_email &&
      to.name !== 'verify' &&
      to.name !== 'send-verify' &&
      to.name !== 'logout'
    ) {
      next({ name: 'verify' })
      return
    }
    if (
      store.state.manager.team === null &&
      to.name !== 'take-team' &&
      to.name !== 'send-verify' &&
      to.name !== 'verify' &&
      to.name !== 'logout'
    ) {
      next({ name: 'take-team' })
      return
    }
  }

  nprogress.start()
  next()
})
router.afterEach((to) => {
  const pageTitleKey = `title.${to.name.replace(/-/g, '_')}`
  store.commit(
    'setPageTitle',
    i18n.te(pageTitleKey) ? i18n.t(pageTitleKey) : ''
  )
  nprogress.done()
})
store.subscribe((mutation) => {
  if (mutation.type === 'setPageTitle') {
    const projectName = process.env.VUE_APP_WS_PROJECT_NAME

    if (store.state.pageTitle) {
      document.title = store.state.pageTitle + ' - ' + projectName
    } else {
      document.title = projectName
    }
  }
})
const maxReloadAttempts = 3
let reloadAttempts = getLocalStorageItem('reloadAttempts') || 0
router.onError((error) => {
  if (
    error &&
    error.name === 'ChunkLoadError' &&
    error.message &&
    error.message.indexOf('Loading chunk') !== -1 &&
    navigator.onLine
  ) {
    if (reloadAttempts < maxReloadAttempts) {
      setLocalStorageItem(
        'reloadAttempts',
        ++reloadAttempts,
        Moment.duration(15, 'minutes')
      )
      console.log('Got loading chunk error. Reloading page…')
      window.location.reload()
    } else {
      console.error(error)
    }
  }
})

export default router
