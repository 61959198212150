function prefixRoutes(prefix, routes) {
  return routes.map((route) => {
    route.path = prefix + '/' + route.path

    return route
  })
}
import NoInternetView from '@/views/Errors/NoInternetView'
export default [
  ...prefixRoutes('/information', [
    {
      path: 'about-project',
      name: 'about-project',
      component: () =>
        import(
          /* webpackChunkName: "AboutProjectView" */ '@/views/Information/AboutProjectView'
        ),
    },
    {
      path: 'contact-offer',
      name: 'contact-offer',
      component: () =>
        import(
          /* webpackChunkName: "ContractOfferView" */ '@/views/Information/ContractOfferView'
        ),
    },
    {
      path: 'privacy-policy',
      name: 'privacy-policy',
      component: () =>
        import(
          /* webpackChunkName: "PrivacyPolicyVIew" */ '@/views/Information/PrivacyPolicyVIew'
        ),
    },
    {
      path: 'project-rules',
      name: 'project-rules',
      component: () =>
        import(
          /* webpackChunkName: "ProjectRulesView" */ '@/views/Information/ProjectRulesView'
        ),
    },
    {
      path: 'where-to-spend',
      name: 'where-to-spend',
      component: () =>
        import(
          /* webpackChunkName: "WhereToSpendView" */ '@/views/Information/WhereToSpendView'
        ),
    },
    {
      path: 'admin-contact',
      name: 'admin-contact',
      component: () =>
        import(
          /* webpackChunkName: "AdminContactView" */ '@/views/Information/AdminContactView'
        ),
    },
    {
      path: 'faq',
      name: 'faq',
      component: () =>
        import(/* webpackChunkName: "FAQView" */ '@/views/Information/FAQView'),
    },
  ]),
  ...prefixRoutes('/error', [
    {
      path: 'not-found',
      name: 'not-found',
      alias: '*',
      component: () =>
        import(
          /* webpackChunkName: "PageNotFoundView" */ '@/views/Errors/PageNotFoundView'
        ),
    },
    {
      path: 'banned',
      name: 'banned',
      component: () =>
        import(
          /* webpackChunkName: "BannedManagerView" */ '@/views/Errors/BannedManagerView'
        ),
      props: true,
    },
    {
      path: 'maintenance',
      name: 'maintenance',
      component: () =>
        import(
          /* webpackChunkName: "MaintenanceView" */ '@/views/Errors/MaintenanceView'
        ),
    },
    {
      path: 'too-many-requests',
      name: 'too-many-requests',
      component: () =>
        import(
          /* webpackChunkName: "TooManyRequestsView" */ '@/views/Errors/TooManyRequestsView'
        ),
      props: true,
    },
    {
      path: 'no-internet',
      name: 'no-internet',
      component: NoInternetView,
      props: true,
    },
  ]),
]
