import './style/main.scss'

import Vue from 'vue'
import router from './router'
import _ from 'lodash'
import http from './http'
import i18n from '@/i18n'
import store from './store'
import Moment from 'moment-timezone'
import 'moment/locale/ru'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import httpMixin from './mixins/http'
import urlsMixin from './mixins/urls'
import tokensMixin from './mixins/tokens'
import Ws from './service/ws'
import Pusher from 'pusher-js'
import loader from 'vue-ui-preloader'
import VueNumericInput from 'vue-numeric-input'
import VueClipboard from 'vue-clipboard2'
import settings from '@/service/settings'

import { Game } from './service/game/game'
import Buefy from 'buefy'

Vue.use(VueCookies, { expire: '30d' })
Vue.use(Buefy)
Vue.use(loader)
Vue.use(VueNumericInput)
Vue.use(VueClipboard)

Vue.config.productionTip = false
Moment.tz.setDefault('Europe/Moscow')
Moment.locale('ru')

Object.defineProperty(Vue.prototype, '$_', { value: _ })
Object.defineProperty(Vue.prototype, '$api', { value: http })
Object.defineProperty(Vue.prototype, '$moment', { value: Moment })
Object.defineProperty(Vue.prototype, '$game', { value: new Game() })
Object.defineProperty(Vue.prototype, '$ws', { value: Ws.getInstance() })
Object.defineProperty(Vue.prototype, '$pcWidth', { value: 1169 })
Object.defineProperty(Vue.prototype, '$tabletWidth', { value: 769 })
Object.defineProperty(Vue.prototype, '$settings', { value: settings })

Vue.mixin(httpMixin)
Vue.mixin(urlsMixin)
Vue.mixin(tokensMixin)

window.Pusher = Pusher

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
