export default {
  stadium_rental: 'Аренда стадиона',
  stadium_rental_and_cost:
    'Здесь вы можете взять в аренду стадион и установить цену на билеты',
  match_rating: 'Рейтинг матча:',
  elo_rating: 'Рейтинг эло:',
  stadium_has: 'На стадионе имеется',
  rental_cost: 'Стоимость аренды за игру (ВД)',
  operating_cost: 'Стоимость эксплуатации за игру (ВД)',
  stadium_places: 'Места на стадионе',
  place_type: 'Тип места',
  place_amount: 'Кол-во',
  ticket_price: 'Цена билета',
  profitability_to_date: 'Доходность на текущий момент',
  expected_attendance: 'Ожидаемая посещаемость (чел)',
  expected_revenue_per_game: 'Ожидаемый доход со стадиона за игру (ВД)',
  expected_net_income_per_game: 'Ожидаемый чистый доход за игру (ВД)',
  rent_stadium: 'Арендовать стадион',
  success_places_cost_update: 'Вы успешно установили цены на билеты!',
}
