import api from '@/http'

const state = () => ({
  availablePlayers: [],
  teamBids: [],
  selectedPlayer: null,
  teamPlayersOnTransfer: [],
  limits: null,
})

const getters = {}

const actions = {
  getAvailablePlayers({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers/available-players`)
        .then(({ data }) => {
          commit('setAvailablePlayers', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addPlayerForTransfer(ctx, data) {
    return new Promise((resolve, reject) => {
      api
        .post(`/transfers/add-player-for-transfer`, data)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTeamBids({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers/team-bids`, {
          params,
        })
        .then(({ data, response }) => {
          commit('setTeamBids', data)
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTransferList(ctx, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers`, {
          params,
        })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTransferItemBids(ctx, transferListId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers/item/${transferListId}/bids`, {
          display404ErrorMessage: true,
        })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addTransferBid(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`/transfers/item/${payload.id}/bid`, payload.bid)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteTransferBid(ctx, transferBid) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/transfers/bids/${transferBid}`, {
          display404ErrorMessage: true,
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTeamPlayersOnTransfer({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers/team-players-on-list`, { params })
        .then(({ data, response }) => {
          commit('setTeamPlayersOnTransfer', data)
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteTeamTransferItem(ctx, id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/transfers/item/${id}`, { display404ErrorMessage: true })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetachedPlayers() {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers/detached-players`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFinishedTransfers(ctx, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers/finished`, { params })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPlayerTransferInfo(ctx, playerId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers/player-info/${playerId}`, {
          display404ErrorMessage: true,
        })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendDirectOffer(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`/transfers/direct-offers/send`, payload)
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTeamTransferOffers() {
    return new Promise((resolve, reject) => {
      api
        .get(`/transfers/direct-offers/to-team`)
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  acceptDirectOffer(ctx, offerId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/transfers/direct-offers/${offerId}/accept`, {
          display404ErrorMessage: true,
        })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  declineDirectOffer(ctx, offerId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/transfers/direct-offers/${offerId}/decline`, {
          display404ErrorMessage: true,
        })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTransferLimits({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/transfers/limits')
        .then(({ data }) => {
          commit('setTransfersLimits', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.availablePlayers = []
    state.teamBids = []
    state.transferList = []
    state.selectedPlayer = null
    state.teamPlayersOnTranfer = []
  },
  setAvailablePlayers(state, players) {
    state.availablePlayers = players
  },
  setSelectedPlayer(state, player) {
    state.selectedPlayer = player
  },
  setTeamBids(state, bids) {
    state.teamBids = bids
  },
  setTeamPlayersOnTransfer(state, list) {
    state.teamPlayersOnTransfer = list
  },
  setTransfersLimits(state, limits) {
    state.limits = limits
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
