export default {
  manager: 'Менеджер клуба',
  not_manager: 'Нет',
  stadium: 'Стадион - {stadiumName} ({capacity})',
  country: 'Страна -',
  league: 'Лига -',
  league_place: 'Сила в лиге/Сила в мире',
  contracts_amount: 'Сумма контрактов - {amount}',
  players_total_cost: 'Общая стоимость игроков -',
  league_place_suffix: '-е место',
  level: ' Ур. - {level}',
  elo: 'Эло - {value}',
  players: 'Список футболистов',
  closest_games: 'Предстоящие игры',
  no_closest_games: 'В этом сезоне игр уже не будет',
  league_places: 'Турнирная таблица',
  name: 'Команда',
  played: 'И',
  won: 'В',
  drawn: 'Н',
  lost: 'П',
  goals_for: '+',
  goals_against: '-',
  points: 'О',
  finished_games: 'Оконченные игры',
  no_finished_games: 'В этом сезоне игр ещё не было',
  workout: 'Провести тренировку',
  sort_by: 'По рейтингу',
  tab: {
    goalkeeper: 'Вратари',
    defender: 'Защитники',
    midfielder: 'Полузащитники',
    attacker: 'Нападающие',
  },
}
