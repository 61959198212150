export default {
  administration: 'Администрация',
  transfer_list: 'Трансферный лист',
  negotiation: 'Переговоры',
  team: 'Моя команда',
  direct_transfer: 'Прямой трансфер',
  direct_transfers: 'Прямые трансферы',
  closed_transfers: 'Завершенные трансферы',
  page_head_text:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
  day: 'дня',
  pair_day: 'дня',
  days: 'дней',
  day_short: 'д.',
  rent: 'Аренда',
  sale: 'Продажа',
  rental_duration: 'Длительность аренды',
  rental_amount: 'Сумма аренды',
  rent_total_info: 'Всего',
  nominal_cost: 'Номинальная стоимость',
  auction_term: 'Срок аукциона',
  form_submit_button: 'Выставить',
  transfer_amount: 'Сумма продажи',
  transfer_input_alarm:
    'занижать или завышать номинальную сумму больше чем на 10% нельзя.',
  expose_player_successfully: 'Игрок успешно выставлен на трансфер',
  your_bids: 'Ваши ставки',
  user_bid: 'Вашa ставка',
  filter_title: 'Отфильтровать',
  filter_by_seller: 'По продавцу',
  filter_by_price: 'По цене',
  filter_by_transfer_type: 'По типу трансфера',
  filter_by_transfer_type_mobile: 'По типу',
  filter_by_role: 'По позиции',
  filter_by_age: 'По возрасту',
  filter_by_salary: 'По зарплате ',
  filter_apply_button: 'Применить',
  filter_from: 'От',
  filter_to: 'До',
  admin: 'Администрация',
  manager: 'Менеджер',
  filter_close_soon: 'Скоро закроются',
  date: 'Дата',
  seller: 'Продавец',
  surname: 'Фамилия',
  table_role: 'П',
  table_country: 'Гр.',
  age: 'Возраст',
  salary: 'Зарплата',
  type: 'Тип',
  term: 'Срок',
  player_level: 'Ур.',
  table_bid: 'Цена ставки',
  create_bid: 'Cделать ставку',
  not_data: 'По запросу данных не найдено',
  offer_modal_title: 'Предложение:',
  rental_period: 'Срок аренды',
  day_cost: 'Стоимость за 1 день',
  transfer_cost: 'Стоимость трансфера:',
  auction: 'Аукцион',
  rent_player: 'Аренда игрока',
  auction_until: 'До конца аукциона  осталось',
  transfer_not_found: 'Трансфер не был найден',
  price: 'Цена',
  player_role: 'Роль',
  sort_date_asc: 'От старых к новым',
  sort_date_desc: 'От новых к старым',
  sort_level_asc: 'От наименьшего уровня к наибольшему',
  sort_level_desc: 'От наибольшего уровня к наименьшему',
  filters: {
    sellerTypes: 'Продавец: {value}',
    transferTypes: 'Тип трансфера: {value}',
    playerRoles: 'Позиция: {value}',
    price: 'Цена: {value}',
    age: 'Возраст: {value}',
    salary: 'Зарплата: {value}',
    willClosedSoon: 'Скоро закроются',
    values: {
      admin: 'Администрация',
      manager: 'Менеджер',
      rent: 'Аренда',
      sale: 'Продажа',
    },
  },
  filter_number_range_from: 'от {value}',
  filter_number_range_to: 'до {value}',
  team_players_on_transfer: 'Ваши игроки на трансфере',
  detached_players: 'Игроки в командировке ',
  from_team: 'Собственик',
  to_team: 'В аренде',
  duration_date: 'Дата окончан.',
  offer: 'Предл.',
  finished_transfers: 'Завершенные трансферы',
  amount_sale: 'Сумма прод.',
  transfer_cost_short: 'Трансфер.ст.',
  age_short: 'В',
  transfer: 'Трансфер',
  transfer_deleted: 'Игрок был успешно удален с трансферного листа',
  delete_confirm_msg:
    'Вы уверены, что хотите удалить игрока {name} с трансферного листа?',
  delete_team_transfer: 'Убрать игрока с трансферного листа',
  show_bids: 'Просмотреть ставки',
  user_request: 'Отправить запрос',
  user_suggestions: 'Мои предложения',
  enter_player_id: 'Введите ID игрока',
  apply: 'Применить',
  player_not_found: 'Заданный игрок не был найден',
  send_request_button: 'Отправить предложение',
  suggestion_from: 'Предложение от: ',
  accept_offer: 'Трансфер подтвержден',
  send_request_success: 'Предложение отправлено',
  decline_offer_success: 'Предложение было отклонено',
  limits: {
    type: 'Тип',
    current_deals: 'Сделок в текущем сезоне',
    max_limit: 'Максимальный лимит',
    position: 'Позиция',
    available_in_team: 'Доступно в  команде',
    min_limit: 'Минимальный лимит',
    transfer_limits: 'Трансферные лимиты',
    deal_limits: 'Лимиты по сделкам',
    deal_limits_small: 'Сделки',
    player_limits: 'Лимиты по игрокам',
    player_limits_small: 'Игроки',
    list_sales: 'Продажи с аукциона',
    list_buy: 'Покупки с аукциона',
    direct_sales: 'Прямые продажи',
    direct_buy: 'Прямые покупки',
    goalkeeper: 'Голкипер',
    defender: 'Защитник',
    midfielder: 'Полузащитник',
    attacker: 'Нападающий',
    total_available_players: 'Всего доступных игроков: {amount}',
    min_available_players: 'Минимум доступных игроков: {amount}',
  },
}
