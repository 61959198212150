import api from '@/http'

const state = () => ({})

const getters = {}

const actions = {
  userSupportRequest(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('/support/request', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPageDescription(ctx, pageKey) {
    return new Promise((resolve, reject) => {
      api
        .get('/support/page-description', {
          params: { page_key: pageKey },
          display404ErrorMessage: true,
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFaqCategories() {
    return new Promise((resolve, reject) => {
      api
        .get('/faq/categories')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFaqContent(ctx, contentItemId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/faq/content/${contentItemId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
