export default {
  select_your_team: 'Выберите свою команду',
  select_league: 'Выберите лигу',
  select_team: 'Выберите клуб',
  enter_manager_login: 'Введите Ваш логин',
  occupied_places: 'Количество занятых мест / всего команд в чемпионате',
  team_country: 'Лига',
  team_name: 'Клуб',
  team_emblem: 'Эмблема',
  team_taken_successfully: 'Вы успешно выбрали команду!',
  total: {
    information: 'Общая информация',
    summary: 'Сводка',
    next: 'Далее',
    prev: 'Назад',
  },
  take_team_confirm_button: 'Взять команду',
}
