export default [
  {
    path: '/registration',
    name: 'registration',
    component: () =>
      import(
        /* webpackChunkName: "RegistrationModalView" */ '@/views/Landing/RegistrationModalView'
      ),
    meta: { parent: 'landing' },
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(
        /* webpackChunkName: "LoginModalView" */ '@/views/Landing/LoginModalView'
      ),
    meta: { parent: 'landing' },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () =>
      import(
        /* webpackChunkName: "ForgotPasswordModalView" */ '@/views/Landing/ForgotPasswordModalView'
      ),
    meta: { parent: 'landing' },
  },
  {
    path: '/forgot-password/process',
    name: 'process-forgot-password',
    component: () =>
      import(
        /* webpackChunkName: "ForgotPasswordProcessModalView" */ '@/views/Landing/ForgotPasswordProcessModalView'
      ),
    meta: { parent: 'landing' },
  },
]
