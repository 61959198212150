export default {
  shop: 'Магазин',
  advertising: 'Реклама',
  sponsors: 'Спонсоры',
  shop_title:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
  shop_goods_amount: `В наличии {amount} из {fullAmount} шт.`,
  agree: 'Да',
  not_agree: 'Нет',
  are_you_sure: `Вы уверены, что хотите купить {quantity} {products} "{name}" на общую сумму: {price} ?`,
  products: 'товар||а|ов',
  success_product_buy: 'Вы успешно приобрели товар!',
  purchased_info: 'Информация о закупках',
  product_type: 'Тип товара',
  product_purchased_price: 'Цена закупки',
  product_sell_price: 'Цена продажи',
  has_no_shop: 'У вас отсутствует постройка магазина',
  sponsors_title:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  contracts: 'Контракты',
  new_offers: 'Предложения',
  choose_sponsor: 'Выберите спонсорский контракт на сезон',
  sponsor_terms: 'Условия',
  sponsor_awards: 'Награды',
  sponsor_sign: 'Подписать',
  not_available_sponsors:
    'В данный момент Вы не можете заключить спонсорский контракт',
  contranct_signed: 'Контракт подписан',
  default_card_empty: 'У вас не выбран спонсор',
  silver_card_empty: 'У вас отсутствует серебряный спонсорский сертификат',
  gold_card_empty: 'У вас отсутствует золотой спонсорский сертификат',
  button_choose_sponsor: 'Выбрать спонсора',
  button_shop: 'В магазин ',
  sponsor_contract: 'Спонсорский контракт',
  advertising_title:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  choose_adv_sponsor: 'Выберите рекламный контракт',
  adv_contract: 'Рекламный контракт',
  adv_period: 'Период: {period} дней',
  adv_awards: 'Выплаты',
  not_available_adv_contracts:
    'В данный момент Вы не можете заключить рекламный контракт', 
  adv_card_empty: {
    default: 'У вас отсутствует рекламный контракт',
    vip: 'У вас отсутствует ВИП контракт', 
    premium: 'У вас отсутствует премиум подписка', 
    platinum: 'У вас отсутствует платиновая подписка',   
  }   
}
