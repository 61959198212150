<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    document.querySelector('html').setAttribute('data-theme', 'default')
    this.syncServerTime()
  },
  methods: {
    syncServerTime() {
      const sentAt = Date.now()
      this.$store.dispatch('getServerTime').then((data) => {
        const currentUtc = Date.now()
        if (currentUtc - sentAt > 2000) {
          setTimeout(this.syncServerTime, 15000)
          return
        }
        const serverOffset = data - currentUtc
        if (Math.abs(serverOffset) > 2000) {
          this.$moment.now = () => {
            return serverOffset + Date.now()
          }
        }
      })
    },
  },
}
</script>

<style></style>
