import api from '@/http'

const state = () => ({
  tournament: null,
  seasons: [],
  tourNumbers: [],
  tournamentReferenceInfo: null,
})

const getters = {}

const actions = {
  getTournamentById({ commit }, tournamentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`tournaments/${tournamentId}`)
        .then(({ data, response }) => {
          commit('setTournament', data)
          if (response?.data?.meta?.seasons) {
            commit('setSeasons', response?.data?.meta?.seasons)
          }
          if (response?.data?.meta?.tour_numbers) {
            commit('setTourNumbers', response?.data?.meta?.tour_numbers)
          }
          if (
            response?.data?.meta?.national_cup ||
            response?.data?.meta?.national_championship
          ) {
            commit(
              'setTournamentReferenceInfo',
              response?.data?.meta?.national_cup ||
                response?.data?.meta?.national_championship
            )
          }
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTourByNumber({ state }, tourNumber) {
    return new Promise((resolve, reject) => {
      api
        .get(`tournaments/${state.tournament.id}/tours/${tourNumber}`)
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTeams({ state }) {
    return new Promise((resolve, reject) => {
      api
        .get(`tournaments/${state.tournament.id}/statistic/teams`)
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBestPlayers({ state }) {
    return new Promise((resolve, reject) => {
      api
        .get(`tournaments/${state.tournament.id}/statistic/best-players`)
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTeamsInfrastructure({ state }) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `tournaments/${state.tournament.id}/statistic/teams/infrastructure`
        )
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFinishedTransfers({ state }, params) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `tournaments/${state.tournament.id}/statistic/finished-transfers`,
          { params }
        )
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.tournament = null
    state.seasons = []
    state.tourNumbers = []
    state.tournamentReferenceInfo = null
  },
  setTournament(state, tournament) {
    state.tournament = tournament
  },
  setSeasons(state, seasons) {
    state.seasons = seasons
  },
  setTourNumbers(state, tourNumbers) {
    state.tourNumbers = tourNumbers
  },
  setTournamentReferenceInfo(state, tournamentReferenceInfo) {
    state.tournamentReferenceInfo = tournamentReferenceInfo
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
