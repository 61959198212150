class CoordsConverter {
  convertHalfMirrorX(val, width) {
    return this.convertMinMax(width - val, 0, width, -1, 0)
  }

  convertX(val, width) {
    return this.convertMinMax(width - val, 0, width, -1, 1)
  }

  convertMirrorX(val, width) {
    return this.convertX(width - val, width)
  }

  convertY(val, height) {
    return this.convertMinMax(val, 0, height, -0.42, 0.42)
  }

  convertMirrorY(val, height) {
    return this.convertY(height - val, height)
  }

  convertXBack(val, width, minWidth = 0) {
    return this.convertMinMax(val, -1, 1, minWidth, width)
  }

  convertMirrorXBack(val, width) {
    return width - this.convertXBack(val, width)
  }

  convertHalfMirrorXBack(val, width) {
    return width - this.convertMinMax(val, -1, 0, 0, width)
  }

  convertYBack(val, height, minHeight = 0) {
    return this.convertMinMax(val, -0.42, 0.42, minHeight, height)
  }

  convertMinMax(input, inputLow, inputHigh, outputLow, outputHigh) {
    return (
      ((input - inputLow) / (inputHigh - inputLow)) * (outputHigh - outputLow) +
      outputLow
    )
  }
}

export { CoordsConverter }
