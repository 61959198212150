export default {
  upcoming_games: 'Предстоящие игры',
  finished_games: 'Оконченные игры',
  shop: 'Магазин',
  sponsors_contracts: 'Спонсорские контракты',
  sponsors_offers: 'Спонсорские предложения',
  advertising_contracts: 'Рекламные контракты',
  advertising_offers: 'Рекламные предложения',
  transfer_list: 'Трансферный лист',
  negotiation: 'Переговоры - Трансферы',
  my_team: 'Моя команда - Трансферы',
  team_roster: 'Ростер',
  transfer_team_request: 'Отправить запрос - Прямые трансферы',
  transfer_team_offers: 'Мои предложения - Прямые трансферы',
  closed_transfers: 'Завершенные трансферы',
  bank: 'Банк',
  vd_report: 'Финансовый отчёт',
  office: 'Офис',
  training_base: 'Тренировочная база',
  spa: 'СПА-центр',
  medical_center: 'Реабилитационный центр',
  stadium: 'Стадион',
  building: 'Строение',
  sponsors: 'Спонсоры',
  advertising: 'Реклама',
  main: '',
  landing: '',
  available_stadiums: 'Аренда стадиона',
  national_championships: 'Чемпионаты',
  national_cups: 'Кубки',
  settings_general: 'Настройки',
  change_password: 'Смена пароля',
  change_email: 'Смена эл. почты',
  wallet: 'Кошелёк',
  manager: 'Менеджер',
  play_game: 'Товарищеская игра',
  mail: 'Почта',
  profile_manager: 'Профиль',
  profile_friends: 'Мои друзья',
  manager_friends: 'Друзья {manager}',
  incoming: 'Входящие заявки - Друзья',
  outgoing: 'Исходящие заявки - Друзья',
  profile_referrals: 'Рефералы',
  login: 'Авторизация',
  registration: 'Регистрация',
  forgot_password: 'Восстановление пароля',
  process_forgot_password: 'Новый пароль',
  project_rules: 'Правила проекта',
  about_project: 'О проекте',
  contact_offer: 'Договор оферты',
  privacy_policy: 'Политика конфиденциальности',
  where_to_spend: 'Куда потратить',
  game_template: 'Состав на игру',
  team_template: 'Состав на игру',
  game: 'Матч',
  tournament: 'Турнир',
  quick_tournament: 'Быстрый турнир',
  logout: 'Выход',
  my_profile: 'Мой профиль',
  player: 'Игрок {fullName}',
  not_found: 'Страница не найдена',
  banned: 'Блокировка',
  maintenance: 'Технические работы',
  too_many_requests: 'Слишком много запросов',
  no_internet: 'Нет соединения',
  project_shop: 'Магазин',
  tournament_teams: 'Команды',
  tournament_best_players: 'Лучшие игроки лиги',
  tournament_infrastructure: 'Инфраструктура',
  tournament_finished_transfers: 'Трансферы',
  tournament_statistic: '{name} - {tournamentName}',
  admin_contact: 'Связь с администрацией',
  create_team: 'Создание команды',
  mail_conversation: 'Почта',
  quick_tournament_tours: 'Быстрый турнир : Туры',
  faq: 'Частые вопросы',
  stadium_sector: 'Сектор',
  stadium_object: 'Объект',
  office_object: 'Объект',
  managers_online: 'Менеджеры онлайн',
}
