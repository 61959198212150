import api from '@/http'

const state = () => ({
  notifications: null,
  hasUnreadNotifications: false,
})

const getters = {}

const actions = {
  getUserNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/profile/notifications')
        .then(({ data }) => {
          data.forEach((notification) => {
            if (notification.read_at === null) {
              commit('setUnreadNotifications', true)
            }
          })
          commit('setNotifications', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  markAllNotificationsAsRead({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .post(`/profile/notifications/mark-all-as-read`)
        .then(({ data }) => {
          commit('setUnreadNotifications', false)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.notifications = null
    state.hasUnreadNotifications = false
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  setUnreadNotifications(state, value) {
    state.hasUnreadNotifications = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
