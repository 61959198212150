import manager from './manager'
import login from './login'
import menu from './menu'
import game from './game'
import global from './global'
import tournaments from './tournaments'
import player from './player'
import landing from './landing'
import footer from './footer'
import team from './team'
import registration from './registration'
import games from './games'
import bank from './bank'
import settings from './settings'
import finance from './finance'
import mainPage from './main_page'
import mail from './mail'
import stadium from './stadium'
import transfers from './transfers'
import verification from './verification'
import restorePassword from './restore_password'
import availableStadiums from './available_stadiums'
import marketing from './marketing'
import wallet from './wallet'
import quickTournament from './quick_tournament'
import office from './office'
import building from './building'
import profile from './profile'
import search from './search'
import friends from './friends'
import support from './support'
import title from './title'
import responseErrors from './response_errors'
import shop from './shop'
import teamSettings from './team_settings'
import inventory from './inventory'
import projectTour from './project_tour'
import takeTeam from './take_team'

export default {
  global,
  manager,
  login,
  menu,
  game,
  tournaments,
  player,
  landing,
  footer,
  team,
  registration,
  games,
  bank,
  settings,
  finance,
  mainPage,
  mail,
  stadium,
  transfers,
  verification,
  restorePassword,
  availableStadiums,
  marketing,
  wallet,
  quickTournament,
  office,
  building,
  profile,
  search,
  friends,
  support,
  title,
  responseErrors,
  shop,
  teamSettings,
  inventory,
  projectTour,
  takeTeam,
}
