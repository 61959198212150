/* eslint-disable */
import api from '@/http'
import Vue from 'vue'
import _ from 'lodash'
import tacticTeamSettings from '@/service/game/tactic_team_settings'
import tacticPositions from '@/service/game/tactic_positions'
import { Game } from '@/service/game/game'

function canBeSwapped(pos1, pos2) {
  if (Game.isReservePosition(pos1) && Game.isReservePosition(pos2)) {
    // not swap reserve players
    return false
  }
  if (Game.isReservePosition(pos1) && !Game.isReservePosition(pos2)) {
    // can swap reserve to main
    return true
  }
  if (Game.isReservePosition(pos2) && !Game.isReservePosition(pos1)) {
    // can swap main to reserve
    return true
  }
  return !Game.isReservePosition(pos1) && !Game.isReservePosition(pos2) // can swap both main players
}

function resolveReplacement(player1, player2) {
  if (
    Game.isReservePosition(player1.pos) &&
    !Game.isReservePosition(player2.pos)
  ) {
    return { fromId: player2.id, toId: player1.id }
  } else if (
    !Game.isReservePosition(player1.pos) &&
    Game.isReservePosition(player2.pos)
  ) {
    return { fromId: player1.id, toId: player2.id }
  }
  return { fromId: null, toId: null }
}

const state = () => ({
  players: [],
  template: {},
  selectedPlayerId: null,
  loadedTemplate: {},
  realtime: false,
  replacements: [],
})

const getters = {
  getPlayerPosition: (state) => (playerId) => {
    for (let position in state.template) {
      if (state.template[position] === playerId) {
        return position
      }
    }
    return null
  },
  getPlayerById: (state) => (playerId) =>
    state.players.find((p) => p.id === playerId),
  playersOutsideTemplate: (state, getters) =>
    state.players.filter((p) => getters.getPlayerPosition(p.id) === null),
  getPlayerOnPosition: (state) => (position) =>
    state.players.find((p) => p.id === state.template[position.toLowerCase()]),
  countPlayersOnField: (state) => {
    let count = 0
    for (const tacticPosition of tacticPositions) {
      if (
        _.get(state.template, tacticPosition.pos) &&
        !tacticPosition.reserve
      ) {
        count++
      }
    }
    return count
  },
  replacements: (state, getters) =>
    state.replacements.map((r) =>
      Object({
        from: getters.getPlayerById(r.from_id),
        to: getters.getPlayerById(r.to_id),
        canBeCancelled: r.executed_at_part === undefined,
      })
    ),
  isGoalkeeperOnPosition: (state, getters) => getters.getPlayerOnPosition('gk'),
  isTeamFullyEquipped: (state, getters) =>
    getters.countPlayersOnField !== 11 || !getters.isGoalkeeperOnPosition,
}

const actions = {
  removePlayerFromPosition({ commit, state }, playerId) {
    for (const position in state.template) {
      if (state.template[position] === playerId) {
        commit('setPlayerPosition', {
          playerId: null,
          position,
        })
      }
    }
    commit('setSelectedPlayerId', null)
  },
  saveTemplate({ commit, state, getters }) {
    return new Promise((resolve, reject) => {
      const payload = {}

      commit('setSelectedPlayerId', null)

      for (const tacticPosition of tacticPositions) {
        payload[tacticPosition.pos] = _.get(
          state.template,
          tacticPosition.pos,
          null
        )
        if (
          payload[tacticPosition.pos] &&
          !getters.getPlayerOnPosition(tacticPosition.pos)
        ) {
          payload[tacticPosition.pos] = null
        }
      }

      for (const teamSetting of tacticTeamSettings) {
        payload[teamSetting] = _.get(state.template, teamSetting, null)
      }

      api
        .post('manager/team/template', payload)
        .then(({ data, response }) => {
          commit('setLoadedTemplate', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveRealtimeFormation(
    { commit, state, getters, dispatch },
    { gameId, teamId }
  ) {
    return new Promise((resolve, reject) => {
      commit('setSelectedPlayerId', null)
      const payload = {}
      const replacements = []

      for (const tacticPosition of tacticPositions) {
        payload[tacticPosition.pos] = _.get(
          state.template,
          tacticPosition.pos,
          null
        )
      }

      for (const replacement of state.replacements) {
        if (replacement.executed_at_part !== undefined) continue

        const fromPlayerPosition = getters.getPlayerPosition(
          replacement.from_id
        )
        const toPlayerPosition = getters.getPlayerPosition(replacement.to_id)

        payload[fromPlayerPosition] = replacement.to_id
        payload[toPlayerPosition] = replacement.from_id
        replacements.push({
          from_id: replacement.from_id,
          to_id: replacement.to_id,
        })
      }
      payload['replacements'] = replacements
      const currentTeamReplacements = state.replacements.map((r) =>
        Object({
          from_id: r.from_id,
          to_id: r.to_id,
          executed_at_part:
            r.executed_at_part === undefined ? null : r.executed_at_part,
        })
      )

      api
        .post(`games/${gameId}/team/${teamId}/realtime-formation`, payload)
        .then(({ data, response }) => {
          dispatch('game/setCurrentTeamReplacements', currentTeamReplacements, {
            root: true,
          })
          dispatch(
            'game/setCurrentTeamTemplate',
            JSON.parse(JSON.stringify(state.template)),
            { root: true }
          )
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveRealtimeSettings({ commit, state, dispatch }, { gameId, teamId }) {
    return new Promise((resolve, reject) => {
      const payload = {}
      commit('setSelectedPlayerId', null)

      for (const teamSetting of tacticTeamSettings) {
        payload[teamSetting] = _.get(state.template, teamSetting, null)
      }

      api
        .post(`games/${gameId}/team/${teamId}/realtime-settings`, payload)
        .then(({ data, response }) => {
          dispatch(
            'game/setCurrentTeamTemplate',
            JSON.parse(JSON.stringify(state.template)),
            { root: true }
          )
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveFormationForGame({ commit, state, rootGetters }) {
    return new Promise((resolve, reject) => {
      const payload = {}
      const requestParams = rootGetters['game/requestParams']

      commit('setSelectedPlayerId', null)

      for (const tacticPosition of tacticPositions) {
        payload[tacticPosition.pos] = _.get(
          state.template,
          tacticPosition.pos,
          null
        )
      }

      for (const teamSetting of tacticTeamSettings) {
        payload[teamSetting] = _.get(state.template, teamSetting, null)
      }

      api
        .post(
          `games/${requestParams.gameId}/team/${requestParams.teamId}/template`,
          payload
        )
        .then(({ data, response }) => {
          commit('setLoadedTemplate', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  copyLoadedTemplateToDisplaying({ commit, state }) {
    commit('setSelectedPlayerId', null)

    for (let position in state.template) {
      commit('setPlayerPosition', { playerId: null, position })
    }

    for (const tacticPosition of tacticPositions) {
      commit('setPlayerPosition', {
        playerId: _.get(state.loadedTemplate, tacticPosition.pos),
        position: tacticPosition.pos,
      })
    }

    for (const teamSetting of tacticTeamSettings) {
      commit('setTeamSetting', {
        setting: teamSetting,
        value: _.get(state.loadedTemplate, teamSetting),
      })
    }
  },
  getDefaultTemplate({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('manager/team/template')
        .then(({ data, response }) => {
          commit('setLoadedTemplate', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPlayers({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/manager/team/players')
        .then(({ data, response }) => {
          commit('setPlayers', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getPlayersForGame({ commit }, { gameId, teamId }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/games/${gameId}/team/${teamId}/players`)
        .then(({ data, response }) => {
          commit('setPlayers', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getTemplateForGame({ commit }, { gameId, teamId }) {
    return new Promise((resolve, reject) => {
      api
        .get(`games/${gameId}/team/${teamId}/template`)
        .then(({ data, response }) => {
          commit('setLoadedTemplate', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  handleSelectPlayerId({ state, getters, commit, dispatch }, playerId) {
    if (state.selectedPlayerId !== null) {
      const currentPlayerPosition = getters.getPlayerPosition(
        state.selectedPlayerId
      )
      const selectedPlayerPosition = getters.getPlayerPosition(playerId)

      if (
        state.realtime &&
        !canBeSwapped(selectedPlayerPosition, currentPlayerPosition)
      ) {
        commit('setSelectedPlayerId', null)
        return
      }

      if (state.realtime) {
        const { fromId, toId } = resolveReplacement(
          { id: state.selectedPlayerId, pos: currentPlayerPosition },
          { id: playerId, pos: selectedPlayerPosition }
        )
        if (fromId !== null && toId !== null) {
          dispatch('processReplacement', { fromId, toId })
        }
      }

      if (currentPlayerPosition !== null) {
        commit('setPlayerPosition', {
          playerId: playerId,
          position: currentPlayerPosition,
        })
      }
      if (selectedPlayerPosition !== null) {
        commit('setPlayerPosition', {
          playerId: state.selectedPlayerId,
          position: selectedPlayerPosition,
        })
      }
      if (currentPlayerPosition !== null || selectedPlayerPosition !== null) {
        commit('setSelectedPlayerId', null)
        return
      }
    }
    commit('setSelectedPlayerId', playerId)
  },

  handleSelectTacticPosition({ state, getters, commit, dispatch }, position) {
    const currentOnPosition = state.template[position]
    if (currentOnPosition) {
      dispatch('handleSelectPlayerId', currentOnPosition)
    } else if (state.selectedPlayerId) {
      const selectedPlayerPosition = getters.getPlayerPosition(
        state.selectedPlayerId
      )

      if (
        !state.realtime ||
        (!Game.isReservePosition(position) &&
          !Game.isReservePosition(selectedPlayerPosition))
      ) {
        commit('setPlayerPosition', {
          playerId: state.selectedPlayerId,
          position: position,
        })
        if (selectedPlayerPosition !== null) {
          commit('setPlayerPosition', {
            playerId: null,
            position: selectedPlayerPosition,
          })
        }
      }

      commit('setSelectedPlayerId', null)
    }
  },

  processReplacement({ state, commit }, { fromId, toId }) {
    const replacements = JSON.parse(JSON.stringify(state.replacements))
    let deleteIdx = null
    let idx = 0
    let changed = false

    for (const replacement of replacements) {
      if (fromId === replacement.to_id && toId === replacement.from_id) {
        deleteIdx = idx
      } else if (
        fromId === replacement.to_id &&
        replacement.executed_at_part === undefined
      ) {
        replacement.to_id = toId
        changed = true
      } else if (toId === replacement.from_id) {
        replacement.from_id = fromId
        changed = true
      }
      idx++
    }

    if (deleteIdx !== null) {
      replacements.splice(deleteIdx, 1)
    }
    if (deleteIdx === null && !changed) {
      replacements.push({ from_id: fromId, to_id: toId })
    }

    commit('setReplacements', replacements)
  },

  cancelReplacement({ commit, dispatch }, { fromId, toId }) {
    commit('setSelectedPlayerId', null)
    dispatch('handleSelectPlayerId', fromId)
    dispatch('handleSelectPlayerId', toId)
  },

  updateTabFromGame({ commit, dispatch, rootGetters }, tab) {
    if (rootGetters['game/currentTemplate'] === null) {
      return
    }

    commit('resetState')
    commit('setLoadedTemplate', rootGetters['game/currentTemplate'])
    dispatch('copyLoadedTemplateToDisplaying')
    commit('setPlayers', rootGetters['game/currentTeamPlayers'])
    commit('setRealtime', true)
    commit(
      'setReplacements',
      JSON.parse(JSON.stringify(rootGetters['game/currentTeamReplacements']))
    )
    commit('game/setTab', tab, { root: true })
  },
  updateGameTeamTemplate({ commit, dispatch }, team) {
    commit('resetState')
    commit('setLoadedTemplate', team.template)
    dispatch('copyLoadedTemplateToDisplaying')
    commit('setPlayers', team.players)
  },
}

const mutations = {
  setPlayers(state, players) {
    state.players = players
  },
  setSelectedPlayerId(state, playerId) {
    if (playerId === state.selectedPlayerId) {
      state.selectedPlayerId = null
    } else {
      state.selectedPlayerId = playerId
    }
  },
  setPlayerPosition(state, { playerId, position }) {
    if (playerId) {
      Vue.set(state.template, position, playerId)
    } else {
      Vue.delete(state.template, position)
    }
  },
  setTeamSetting(state, { setting, value }) {
    Vue.set(state.template, setting, value)
  },
  setLoadedTemplate(state, template) {
    state.loadedTemplate = template
  },
  setRealtime(state, realtime) {
    state.realtime = realtime
  },
  setReplacements(state, replacements) {
    state.replacements = replacements
  },
  resetState(state) {
    state.players = []
    state.template = {}
    state.selectedPlayerId = null
    state.loadedTemplate = {}
    state.realtime = false
    state.replacements = []
  },
  removeReplacement(state, { fromId, toId }) {
    state.replacements = state.replacements.filter(
      (r) => r.from_id !== fromId && r.to_id !== toId
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
