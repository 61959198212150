import api from '@/http'

const state = () => ({
  newManagerLogin: '',
  teamData: {
    name: '',
    imageNumber: 1,
    countryId: null,
    countryCode: '',
  },
})

const getters = {}

const actions = {
  createTeam({ state }) {
    return new Promise((resolve, reject) => {
      api
        .post('teams/create', {
          name: state.teamData.name,
          image_number: state.teamData.imageNumber,
          country_id: state.teamData.countryId,
          manager_login: state.newManagerLogin,
        })
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setTeamName(state, name) {
    state.teamData.name = name
  },
  setTeamImageNumber(state, imageNumber) {
    state.teamData.imageNumber = imageNumber
  },
  setTeamCountryId(state, country) {
    state.teamData.countryId = country
  },
  setTeamCountryCode(state, country) {
    state.teamData.countryCode = country
  },
  setNewManagerLogin(state, managerLogin) {
    state.newManagerLogin = managerLogin
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
