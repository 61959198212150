export default {
  buildingTypes: {
    trainingBase: 'training_base',
    spa: 'spa',
    medicalCenter: 'medical_center',
  },
  appUrl: process.env.VUE_APP_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  tokenPielsColors: {
    main: {
      main: '#FED425',
      light: '#FEB724',
      dark: '#FEE475',
      semidark: '#FB9D12',
    },
    recovery: {
      main: '#5BA176',
      light: '#68B787',
      dark: '#467C5B',
      semidark: '#355C44',
    },
    power: {
      main: '#D22F27',
      light: '#EE534B',
      dark: '#A12620',
      semidark: '#8C130D',
    },
    pump: {
      main: '#6816B7',
      light: '#8D4DCB',
      dark: '#371359',
      semidark: '#4C1A7C',
    },
    loyalty: {
      main: '#085CFE',
      light: '#3A7DFF',
      dark: '#0344C2',
      semidark: '#004ADA',
    },
  },
}
