export default {
  general_information: 'Общая информация',
  general_statistic: 'Общая статистика',
  country: 'Национальность',
  not_country: 'Нет',
  role: 'Позиция',
  age: 'Возраст',
  birth_date: 'День рождения',
  lvl: 'Уровень',
  physical_condition: 'Физ. кондиции',
  state: 'Состояние',
  level: 'Ур.{level}',
  id: 'ИД',
  copy: 'Копировать',
  main_parameters: 'Проф. параметры',
  second_parameters: 'Доп. параметры',
  player_tooltip_message: 'Сегодня уровень был прокачан на',
  player_tooltip_message_small: 'Сегодня прокачан на',
  player_price_header: 'Transfer price:',
  player_salary: 'Зарплата',
  player_transfer_price: 'Трансферная стоимость',
  player_main_parameter: 'Основной параметр',
  parameters: {
    physical_velocity: 'Скорость',
    physical_shotpower: 'Сила удара',
    technical_dribble: 'Дриблинг',
    technical_ballcontrol: 'Контроль мяча',
    technical_shot: 'Техника удара',
    technical_volley: 'Удар мяча с лёта',
    physical_balance: 'Физическое состояние',
    physical_reaction: 'Реакция',
    physical_stamina: 'Выносливость',
    physical_acceleration: 'Ускорение',
    physical_agility: 'Ловкость',
    technical_standingtackle: 'Отбор',
    technical_slidingtackle: 'Подкат',
    technical_shortpass: 'Короткий пас',
    technical_highpass: 'Дальний пас',
    mental_calmness: 'Спокойствие',
    mental_workrate: 'Работоспособность',
    mental_defensivepositioning: 'Игра в обороне',
    mental_vision: 'Виденье игры',
  },
  statistic: {
    played: 'Сыграно игр',
    goals: 'Голов',
    shoots: 'Ударов',
    yellow_cards: 'Кол-во ЖК',
    red_cards: 'КК',
    minutes: 'Минут на поле (среднее)',
    passes_accuracy: 'Точность передач (%)',
  },
  history: 'История трансферов',
  administration: 'Администрация',
  transfer_type_sale: 'Покупка игрока',
  transfer_type_rent: 'Аренда игрока',
  history_rent_days: '({days}д.)',
  name: 'Имя',
  surname: 'Фамилия',
  rudeness: 'Грубость',
  parameters_increased: 'Прокачка игрока',
  all: 'Все параметры',
  main: 'Проф. параметры',
  second: 'Доп. параметры',
  bulk_pump_title: 'Выберите параметры которые хотите прокачать',
  bulk_pump_max: 'Данные параметры уже прокачаны на максимум',
  pump: 'Прокачать',
  success_parameter_pump: 'Вы успешно прокачали параметр игрока',
  success_parameters_pump: 'Вы успешно прокачали выбранные параметры игрока',
  pump_parameter: 'Увеличить параметр',
  parameter_increase: 'Параметр будет прокачан на {amount}',
  parameters_increase: 'Параметры будут прокачаны на {amount}',
  pump_all_parameters: 'Прокачать параметры',
  parameter_unit: 'едини|цу|цы|ц',
}
