import api from '@/http'

const state = () => ({
  inventoryItems: [],
})

const getters = {}

const actions = {
  getItems({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('inventory/items')
        .then(({ data }) => {
          commit('setInventoryItems', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  useItem(ctx, { inventoryItemId, count }) {
    return new Promise((resolve, reject) => {
      api
        .post(`inventory/items/${inventoryItemId}/use`, { count })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.inventoryItems = []
  },
  setInventoryItems(state, inventoryItems) {
    state.inventoryItems = inventoryItems
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
