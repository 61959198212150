import api from '@/http'

const state = () => ({
  packages: null,
})

const getters = {}

const actions = {
  getPackages({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/exchange/packages')
        .then(({ data }) => {
          commit('setPackages', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  buyPackage(ctx, packageId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/exchange/packages/${packageId}/buy`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getProvidersByExchangePackage(ctx, exchangePackageId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/real-payments/${exchangePackageId}/providers`)
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPaymentUrl(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('/real-payments/payment-url', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.packages = null
  },
  setPackages(state, packages) {
    state.packages = packages
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
