export default {
  registration: 'Регистрация',
  login: 'Логин',
  password: 'Пароль',
  email: 'Эл.почта',
  agree_with: 'Я согласен с',
  rules_of_the_project: 'правилами проекта {projectName} ',
  create_account: 'Создать аккаунт',
  you_must_agree_with_the_rules:
    'Вы должны согласится с правилами проекта для регистрации',
  referrer_register: 'Вы пришли по приглашению игрока: ',
}
