export default {
  football_online_manager: 'Футбольный онлайн менеджер',
  registration: 'Регистрация',
  login: 'Войти',
  what_awaits_you: 'Что вас ждёт?',
  full_immersion_in_the_world_of_football: 'Полное погружение в мир футбола!',
  transfers_finance_youth_sports_school_etc: 'Трансферы, финансы, ДЮСШ и др.',
  implemented_game_online: 'Реализована "Игра онлайн"',
  two_D_visualization_of_a_football_match: '2D-визуализация футбольного матча',
  real_championships_and_tournaments: 'Реальные чемпионаты и турниры ',
  various_workouts: 'Разнообразные тренировки',
  cash_prizes_and_payments: 'Денежные призы и выплаты',
  what_do_we_offer: 'Что мы предлагаем',
  line_up_for_the_next_game: 'Выставление состава на ближайшую игру',
  road_to_victory: 'Путь к победе',
  easy_registration: 'Простая регистрация',
  carrying_out_transfers: 'Проведение трансферов',
  club_selection: 'Выбор клуба',
  choice_of_tactics: 'Выбор тактики',
  creation_of_infrastructure: 'Создание инфраструктуры',
  regular_workouts: 'Регулярные тренировки',
  our_champions: 'Наши чемпионы',
  start_your_journey_as_a_champion: 'Начни свой путь чемпиона!',
  calendar: 'Насыщенный игровой календарь',
  transfers: 'Трансферы',
  active_market: 'Активный рынок',
  text_broadcast: 'Есть и текстовая трансляция',
  token_earnings: 'Реализованы онлайн турниры для заработка токенов',
  workouts: 'Не забывай тренироваться, чтобы быть сильнее',
  prizes: 'Играй и зарабатывай!',
  game_slider: '2D-визуализация футбольного матча в режиме реального времени',
  game_slider_mobile: '2D-визуализация футбольного матча',
  transfers_slider:
    'Трансферный рынок. Возможность участвовать в аукционах за игроков',
  transfers_slider_mobile: 'Трансферный рынок',
  roster_slider: 'Ростер команды. Детальная статистика Ваших результатов',
  roster_slider_mobile: 'Ростер команды',
  office_slider: 'Офис. Развитие инфраструктуры Вашей команды',
  office_slider_mobile: 'Офис',
  quick_tournament_slider:
    'Прими участие в быстрых турнирах для заработка внутриигровой валюты',
  quick_tournament_slider_mobile: 'Быстрые турниры',
  stadium_slider: 'Детальное планирование застройки Вашего стадиона',
  stadium_slider_mobile: 'Стадион',
}
