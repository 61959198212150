export default {
  make_changes: 'Внести изменения',
  continue_game: 'Продолжить игру',
  ready_to_game: 'готов к игре',
  should_set_formation: 'Необходимо выставить состав на игру',
  reserved_players: 'Запасные игроки',
  players_list: 'Список игроков',
  stamina_short: 'В',
  save: 'Сохранить',
  replacements: 'Замены',
  team_tactic: 'Командная тактика',
  translation: 'Трансляция',
  game_statistic: 'Статистика матча',
  attack: 'Атака',
  defense: 'Оборона',
  pressure: 'Прессинг',
  send_team: 'Отправить состав',
  settings_saved_successfully: 'Настройки были успешно сохранены',
  formation_saved_successfully: 'Расстановка была успешно сохранена',
  save_error: 'Во время сохранения произошла ошибка',
  formation: 'Растановка',
  tactic: 'Тактика',
  info_shoots: 'Ударов',
  info_shoots_on_target: 'Ударов в створ ворот',
  info_yellow_cards: 'Желтые карточки',
  info_red_cards: 'Красные карточки',
  info_corners: 'Угловые',
  info_shoots_accuracy: 'Точность ударов %',
  info_passes_accuracy: 'Точность передач %',
  info_offsides: 'Офсайдов',
  info_fouls: 'Фолов',
  waiting_teams: 'Команды в ожидании',
  empty_game_waiters: 'Список ожидания пуст',
  remove_from_list: 'Убрать из списка',
  send_request: 'Отправить запрос',
  add_to_list: 'Добавить в список',
  clean: 'Очистить',
  cancel_request: 'Отменить запрос',
  already_canceled: 'Вызов уже был отменен',
  waiting_team_answer:
    'Вы отправили вызов команде {name} сыграть товарищескую игру. Ожидание ответа...',
  confirm: 'Принять',
  decline: 'Отклонить',
  game_request_modal: 'Вам пришел вызов сыграть товарищеский матч:',
  request_was_declined: 'Команда {name} отклонила вызов',
  request_was_cancelled: 'Вызов был отменен',
  chat: 'Чат',
  chat_message: 'Сообщение...',
  message_too_short: 'Сообщение должно быть не менее {min} символов',
  will_start: 'Игра начнется',
  translation_unavailable: 'Трансляция более недоступна',
  technical_loose: 'Техническое поражение',
  technical_draw: 'Техническая ничья',
  home_team_not_set_tactic: 'Команда хозяев не выставила игроков на матч',
  guest_team_not_set_tactic: 'Команда гостей не выставила игроков на матч',
  both_team_not_set_tactic: 'Обе команды не выставили игроков на матч',
  generation_error: 'Ошибка при генерации игры',
  mut: 'Мут',
  mut_until_text:
    'Вы не можете писать сообщения в чат. Время разблокировки: {until}',
  mut_until_text_with_reason:
    'Вы не можете писать сообщения в чат. Время разблокировки: {until}. Причина блокировки: {reason}',
  delete_message: '[удалить]',
  companions: 'Менеджеры',
  rooms: 'Комнаты',
  messages: 'Сообщения',
  rotate_screen: 'Разверните, пожалуйста экран!',
  not_available_players: 'Недоступные игроки',
  comments: {
    $1st_half: 'Начинается первый тайм',
    $2nd_half: 'Начинается второй тайм',
    $penalty_set: 'Пенальти!',
    $penalty_series_start: 'Начинается серия пенальти',
    $penalty_goal: '!!!Гол!!!',
    $penalty_failed: 'Не забил!',
    replacement: 'Замена',
    goal: 'Гол!!!',
    yellow_card: 'Желтая карточка',
    second_yellow_card: 'Красная карточка',
    posts: 'Штанга!',
    crossbar: 'Перекладина!',
    injury: 'Травма',
    penalty_shoot: 'Пробивает пенальти!',
  },
  rewind: 'Перемотка',
  friendly_game: 'Товарищеская игра',
  game_team_formation: 'Расстановка команды {teamName}',
  selected_player_info: {
    shoots: 'Кол-во ударов',
    shoots_on_target: 'Кол-во ударов в створ',
    goals: 'Кол-во голов',
    yellow_cards: 'Кол-во жёлтых карточек',
    red_cards: 'Кол-во красных карточек',
    accurate_passes: 'Кол-во точных передач',
    touching_ball: 'Кол-во касаний мяча',
    tackles: 'Кол-во отборов мяча',
  },
  count_players_on_field:
    'Выставлено {countPlayersOnField} / 11 игроков на поле',
  goalkeeper_on_field: 'На поле обязательно должен быть выставлен голкипер',
  main_comments: {
    comment_1st_half: 'Начался первый тайм',
    comment_2nd_half: 'Начался второй тайм',
    comment_penalty_set: 'Пенальти!',
    comment_penalty_series_start: 'Началась серия пенальти',
  },
  main_comments_title: 'Основные события матча',
  game_stadium: {
    stadium_name: 'Стадион - ',
    date: 'Дата',
    time: 'Время',
    tournament: 'Турнир',
    tour: 'Тур',
    capacity: 'Вместимость стадиона',
    stage: 'Стадия',
    spectators: 'Зрителей',
    training: 'Тренировочный',
  },
  not_fielded_players: 'Игроки ещё не выставленны',
  penalty_series_exists: 'Идёт серия пенальти',
  game_end: 'Игра завершена',
  minute_short: 'мин.',
  level: 'Ур. {level}',
}
