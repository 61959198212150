<template>
  <div class="error-page">
    <div class="error-page__img">
      <NoInternetIcon />
    </div>
    <h1 class="error-page__title">
      {{ $t('responseErrors.no_inet') }}
    </h1>
    <p class="error-page__subtitle">
      {{ $t('responseErrors.inet_connection') }}
    </p>
    <div class="error-page__button">
      <b-button
        class="error-page__button"
        type="is-success"
        rounded
        expanded
        @click="reload"
        >{{ $t('responseErrors.reload') }}</b-button
      >
    </div>
  </div>
</template>

<script>
import router from '@/router'
import NoInternetIcon from '@/components/Svg/Errors/NoInternetIcon'
export default {
  name: 'NoInternetView',
  components: {
    NoInternetIcon,
  },
  methods: {
    reload() {
      router.push({ name: this.$route.params.previousPage })
    },
  },
}
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  background-image: url('@/assets/errors/error_page_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 25px 0;
  @media (max-width: $mobile-width) {
    min-height: 300px;
    padding: 25px 15px;
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 250px;
    @media (max-width: $mobile-width) {
      min-width: 150px;
    }
  }
  &__title {
    max-width: 700px;
    text-align: center;
    @media (max-width: $mobile-width) {
      font-size: 25px;
    }
  }
  &__subtitle {
    margin: 10px 0;
    text-align: center;
  }
  &__button {
    margin-top: 20px;
  }
}
</style>
