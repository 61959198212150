import api from '@/http'

const state = () => ({})

const getters = {}

const actions = {
  quickTournamentsRegister() {
    return new Promise((resolve, reject) => {
      api
        .post(`/quick-tournaments/register`)
        .then(({ data, response }) => {
          this.dispatch('manager/getManagerStatus')
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  quickTournamentsDeregister() {
    return new Promise((resolve, reject) => {
      api
        .post(`/quick-tournaments/deregister`)
        .then(({ data, response }) => {
          this.dispatch('manager/getManagerStatus')
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTournamentWinners(ctx, tournamentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/quick-tournaments/${tournamentId}/winner`)
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
