import api from '@/http'
import _ from 'lodash'

const state = () => ({
  game: {},
  parts: [],
  paused: false,
  executeSteps: 0,
  milliseconds: 0,
  currentPart: 0,
  currentStepIndex: 0,
  availablePartNumbers: [],
  availableParts: [],
  maxFps: 25,
  visualHomeGoals: 0,
  visualGuestGoals: 0,
  tab: 'translation',
  secondsUntilNextGeneration: 0,
  playersFatigue: {},
  animationsPause: false,
  userInputPause: false,
  gameMinutesChanging: false,
})

const getters = {
  fullGameMilliseconds: () => 900_000 * 6,
  minutes: (state) => parseInt(state.milliseconds / 60000),
  requestParams: (state, getters, rootState) =>
    Object({ gameId: state.game.id, teamId: rootState.manager.team.id }),
  finishedParts: (state) =>
    state.parts.filter((part) => part.status === 'finished'),
  displayedLastVisualMilliseconds: (state, getters) =>
    getters.finishedParts.length * 900_000 === state.milliseconds,
  currentTimeSide: (state, getters, rootState) => {
    const currentTeamId = _.get(rootState.manager.team, 'id', null)
    if (currentTeamId === _.get(state.game, 'home_team.id')) {
      return 'home'
    }
    if (currentTeamId === _.get(state.game, 'guest_team.id')) {
      return 'guest'
    }
    return null
  },
  penaltySeriesExists: (state) => {
    return state.game.comments.some(
      (comment) => comment.type === 'penalty_series_start'
    )
  },
  isCurrentTeamParticipant: (state, getters) => {
    return getters.currentTimeSide !== null
  },
  currentTeamPlayers: (state, getters) => {
    if (getters.currentTimeSide === 'home') {
      return state.game.home_team.players
    } else if (getters.currentTimeSide === 'guest') {
      return state.game.guest_team.players
    }
    return []
  },
  homeTeamPlayers: (state) => state.game?.home_team?.players ?? [],
  guestTeamPlayers: (state) => state.game?.guest_team?.players ?? [],
  getPlayerSideById: (state, getters) => (playerId) => {
    if (getters.homeTeamPlayers.some((p) => p.id === playerId)) {
      return 'home'
    }
    if (getters.guestTeamPlayers.some((p) => p.id === playerId)) {
      return 'guest'
    }

    return null
  },
  findPlayerById: (state, getters) => (playerId) => {
    let homeTeamPlayer = getters.homeTeamPlayers.find((p) => p.id === playerId)
    if (homeTeamPlayer) {
      return homeTeamPlayer
    }

    let guestTeamPlayer = getters.guestTeamPlayers.find(
      (p) => p.id === playerId
    )
    if (guestTeamPlayer) {
      return guestTeamPlayer
    }

    return null
  },

  currentTeamReplacements: (state, getters) => {
    if (getters.currentTimeSide === 'home') {
      return state.game.home_team.replacements
    } else if (getters.currentTimeSide === 'guest') {
      return state.game.guest_team.replacements
    }
    return []
  },
  currentTemplate: (state, getters) => {
    if (getters.currentTimeSide === 'home') {
      return state.game.home_team.template ?? {}
    } else if (getters.currentTimeSide === 'guest') {
      return state.game.guest_team.template ?? {}
    }
    return null
  },
  currentContinueGame: (state, getters) => {
    if (getters.currentTimeSide === 'home') {
      return state.game.home_team.continue_game
    } else if (getters.currentTimeSide === 'guest') {
      return state.game.guest_team.continue_game
    }
    return null
  },
  currentNotExecutedReplacements: (state, getters) => {
    let replacements = []
    if (getters.currentTimeSide === 'home') {
      replacements = state.game.home_team.replacements
    } else if (getters.currentTimeSide === 'guest') {
      replacements = state.game.guest_team.replacements
    }
    return replacements.filter((r) => r.executed_at_part === null)
  },
  actualComments: (state) =>
    state.game.comments.filter((c) => c.milliseconds <= state.milliseconds),
  penaltyGoalsFromActualComments: (state, getters) => {
    let goals = [0, 0]

    for (let i = 0; i < getters.actualComments.length; i++) {
      if (getters.actualComments[i].type === 'penalty_goal') {
        const prevPlayerSide = getters.getPlayerSideById(
          getters.actualComments[i - 1].player1_id
        )
        if (prevPlayerSide === 'home') {
          goals[0]++
        }
        if (prevPlayerSide === 'guest') {
          goals[1]++
        }
      }
    }

    return goals
  },
  canTabBeOpened: (state, getters) => (tab) => {
    if (tab === 'translation') {
      return true
    }
    if (tab === 'info' && state.game.status !== 'created') {
      return true
    }
    if (
      getters.currentTimeSide === null ||
      getters.currentContinueGame ||
      state.secondsUntilNextGeneration === 0 ||
      state.game.is_instant ||
      state.game.status !== 'in_progress'
    ) {
      return false
    }
    if (tab === 'settings') {
      return true
    }
    if (
      tab === 'replacement' &&
      getters.currentNotExecutedReplacements.length === 0
    ) {
      return true
    }

    return false
  },
  isGameFinished: (state, getters) => {
    if (state.game.status !== 'finished' || state.animationsPause) {
      return false
    }
    if (getters.penaltySeriesExists) {
      return state.game.comments.at(-1).milliseconds < state.milliseconds
    }
    return getters.minutes === 90
  },
}

const actions = {
  getGames() {
    return new Promise((resolve, reject) => {
      api
        .get('manager/team/games')
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getGame({ commit }, gameId) {
    return new Promise((resolve, reject) => {
      api
        .get(`games/${gameId}`)
        .then(({ data, response }) => {
          commit('setGame', _.omit(data, ['parts']))
          commit('setParts', data.parts)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  continueGame({ getters }) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `games/${getters.requestParams.gameId}/team/${getters.requestParams.teamId}/continue-game`
        )
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendTeam({ getters }) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `games/${getters.requestParams.gameId}/team/${getters.requestParams.teamId}/send-team`
        )
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setCurrentTeamReplacements({ commit, getters }, replacements) {
    if (getters.currentTimeSide === 'home') {
      commit('setHomeTeamReplacements', replacements)
    } else if (getters.currentTimeSide === 'guest') {
      commit('setGuestTeamReplacements', replacements)
    }
  },
  setCurrentTeamTemplate({ commit, getters }, template) {
    if (getters.currentTimeSide === 'home') {
      commit('setHomeTeamTemplate', template)
    } else if (getters.currentTimeSide === 'guest') {
      commit('setGuestTeamTemplate', template)
    }
  },
  setCurrentTeamContinueGame({ commit, getters }, continueGame) {
    if (getters.currentTimeSide === 'home') {
      commit('setHomeTeamContinueGame', continueGame)
    } else if (getters.currentTimeSide === 'guest') {
      commit('setGuestTeamContinueGame', continueGame)
    }
  },
}

const mutations = {
  setGame(state, game) {
    state.game = game
  },
  setParts(state, parts) {
    state.parts = parts
  },
  setPaused(state, paused) {
    state.paused = paused
  },
  setGameMinutesChanging(state, gameMinutesChanging) {
    state.gameMinutesChanging = gameMinutesChanging
  },
  setAnimationsPause(state, animationsPause) {
    state.animationsPause = animationsPause
  },
  setUserInputPause(state, userInputPause) {
    state.userInputPause = userInputPause
  },
  addExecuteSteps(state, val) {
    state.executeSteps += val
  },
  setExecuteSteps(state, executeSteps) {
    state.executeSteps = executeSteps
  },
  setMilliseconds(state, ms) {
    state.milliseconds = ms
  },
  addAvailablePartNumber(state, number) {
    let currentParts = state.availablePartNumbers
    currentParts.push(number)
    state.availablePartNumbers = _.sortBy(_.uniq(currentParts))
  },
  addAvailablePart(state, part) {
    state.availableParts.push(part)
    state.availableParts = _.sortBy(_.uniqBy(state.availableParts, 'number'), [
      'number',
    ])
  },
  setAvailablePartNumbers(state, numbers) {
    state.availablePartNumbers = numbers
  },
  setCurrentPart(state, part) {
    state.currentPart = part
  },
  setCurrentStepIndex(state, index) {
    state.currentStepIndex = index
  },
  setMaxFps(state, fps) {
    state.maxFps = fps
  },
  setVisualHomeGoals(state, goals) {
    state.visualHomeGoals = goals
  },
  setVisualGuestGoals(state, goals) {
    state.visualGuestGoals = goals
  },
  resetState(state) {
    state.game = {}
    state.parts = []
    state.paused = false
    state.gameMinutesChanging = false
    state.animationsPause = false
    state.userInputPause = false
    state.executeSteps = 0
    state.milliseconds = 0
    state.currentPart = 0
    state.currentStepIndex = 0
    state.availablePartNumbers = []
    state.availableParts = []
    state.maxFps = 25
    state.tab = 'translation'
    state.visualHomeGoals = 0
    state.visualGuestGoals = 0
    state.secondsUntilNextGeneration = 0
    state.playersFatigue = {}
  },
  setTab(state, tab) {
    state.tab = tab
  },
  setHomeTeamReplacements(state, replacements) {
    const game = state.game
    game.home_team.replacements = replacements
    state.game = game
  },
  setGuestTeamReplacements(state, replacements) {
    const game = state.game
    game.guest_team.replacements = replacements
    state.game = game
  },
  setHomeTeamTemplate(state, template) {
    const game = state.game
    game.home_team.template = template
    state.game = game
  },
  setGuestTeamTemplate(state, template) {
    const game = state.game
    game.guest_team.template = template
    state.game = game
  },
  setSecondsUntilNextGeneration(state, seconds) {
    state.secondsUntilNextGeneration = seconds
  },
  setHomeTeamContinueGame(state, continueGame) {
    state.game.home_team.continue_game = continueGame
  },
  setGuestTeamContinueGame(state, continueGame) {
    state.game.guest_team.continue_game = continueGame
  },
  setPlayerFatigue(state, { playerId, fatigue }) {
    state.playersFatigue[playerId] = fatigue
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
