import api from '@/http'

const state = () => ({
  loader: false,
  loginModalLoading: false,
  playerMainParameters: null,
  pageTitle: null,
  currentWebSocketConnectionStatus: null,
  searchActive: false,
  projectTourEnabled: false,
  playButtonDropdownClosable: true,
  navbarActive: false,
  managerOnThePage: true,
  managerOutOfThePageTooLong: false,
})

const getters = {}

const actions = {
  getServerTime() {
    return new Promise((resolve, reject) => {
      api
        .get('app/server-time')
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPlayerMainParameters({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/global-info/player-main-parameters')
        .then(({ data }) => {
          commit('setPlayerMainParameters', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setLoader(state, loading) {
    state.loader = loading
  },
  setLoginModalLoading(state, loading) {
    state.loginModalLoading = loading
  },
  toggleLoader(state) {
    state.loader = !state.loader
  },
  setPlayerMainParameters(state, playerMainParameters) {
    state.playerMainParameters = playerMainParameters
  },
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle
  },
  setCurrentWebSocketConnectionStatus(state, currentWebSocketConnectionStatus) {
    state.currentWebSocketConnectionStatus = currentWebSocketConnectionStatus
  },
  setSearchActive(state, searchActive) {
    state.searchActive = searchActive
  },
  setProjectTourEnabled(state, projectTourEnabled) {
    state.projectTourEnabled = projectTourEnabled
  },
  setPlayButtonDropdownClosable(state, playButtonDropdownClosable) {
    state.playButtonDropdownClosable = playButtonDropdownClosable
  },
  setNavbarActive(state, navbarActive) {
    state.navbarActive = navbarActive
  },
  setManagerOnThePage(state, managerOnThePage) {
    state.managerOnThePage = managerOnThePage
  },
  setManagerOutOfThePageTooLong(state, managerOutOfThePageTooLong) {
    state.managerOutOfThePageTooLong = managerOutOfThePageTooLong
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
