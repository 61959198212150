<template>
  <svg
    width="335"
    height="244"
    viewBox="0 0 335 244"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M167.629 214.603C183.035 214.603 195.524 204.731 195.524 192.555C195.524 180.378 183.035 170.506 167.629 170.506C152.223 170.506 139.734 180.378 139.734 192.555C139.734 204.731 152.223 214.603 167.629 214.603Z"
      fill="#F5C746"
    />
    <path
      d="M314.544 207.253L329.421 195.494C336.86 189.615 336.86 180.796 329.421 174.916C321.983 169.037 310.825 169.037 303.386 174.916L288.509 186.675L273.631 174.916C266.192 169.037 255.034 169.037 247.596 174.916C240.157 180.796 240.157 189.615 247.596 195.494L262.473 207.253L247.596 219.012C240.157 224.892 240.157 233.711 247.596 239.59C251.315 242.53 256.894 244 260.613 244C264.333 244 269.912 242.53 273.631 239.59L288.509 227.831L303.386 239.59C307.105 242.53 312.684 244 316.404 244C320.123 244 325.702 242.53 329.421 239.59C336.86 233.711 336.86 224.892 329.421 219.012L314.544 207.253Z"
      fill="#F5C746"
    />
    <path
      d="M167.627 114.651C141.592 114.651 117.416 123.47 100.679 138.169C93.2401 144.049 93.2401 152.868 100.679 158.747C108.117 164.627 119.275 164.627 126.714 158.747C149.03 139.639 186.224 139.639 206.68 158.747C210.399 161.687 215.978 163.157 219.698 163.157C223.417 163.157 228.996 161.687 232.716 158.747C240.154 152.868 240.154 144.049 232.716 138.169C217.838 123.47 193.662 114.651 167.627 114.651Z"
      fill="#F5C746"
    />
    <path
      d="M256.893 114.648C260.612 117.588 264.332 119.057 269.911 119.057C275.49 119.057 279.209 117.588 282.928 114.648C290.367 108.768 290.367 99.9491 282.928 94.0696C251.314 70.5517 210.401 57.3229 169.488 57.3229C128.576 57.3229 85.8031 70.5517 56.0484 94.0696C48.6097 99.9491 48.6097 108.768 56.0484 114.648C63.4871 120.527 74.6451 120.527 82.0838 114.648C106.26 95.5395 137.874 85.2504 171.348 85.2504C204.822 85.2504 230.858 95.5395 256.893 114.648Z"
      fill="#F5C746"
    />
    <path
      d="M303.384 70.5538C307.103 73.4935 310.823 74.9634 316.402 74.9634C321.981 74.9634 325.7 73.4935 329.42 70.5538C336.858 64.6743 334.999 55.8551 327.56 49.9756C282.928 17.6385 225.278 0 165.768 0C106.259 0 52.3283 16.1686 5.83647 48.5057C-1.60222 54.3852 -1.60222 63.2044 3.9768 69.0839C11.4155 74.9634 22.5735 74.9634 30.0122 70.5538C70.925 42.6262 117.417 27.9275 167.628 27.9275C217.839 27.9275 264.331 42.6262 303.384 70.5538Z"
      fill="#F5C746"
    />
  </svg>
</template>

<script>
export default {
  name: 'NoInternetIcon',
}
</script>

<style></style>
