import Echo from 'laravel-echo'
import store from '@/store'

class Ws {
  static instance = null

  constructor() {
    this.$store = store
    this.$echo = null
  }

  connect() {
    this.$echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_WS_KEY,
      wsHost: process.env.VUE_APP_WS_HOST,
      wsPort: process.env.VUE_APP_WS_PORT,
      disableStats: true,
      forceTLS: process.env.VUE_APP_WS_FORCE_TLS === 'true',
      disabledTransports: ['xhr_streaming', 'xhr_polling', 'sockjs'],
      authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
      userAuthentication: {
        endpoint: process.env.VUE_APP_API_URL + '/broadcasting/user-auth',
        transport: 'ajax',
        headers: {
          Authorization: `Bearer ${this.$store.state.manager.authToken}`,
        },
      },
      auth: {
        headers: {
          Authorization: `Bearer ${this.$store.state.manager.authToken}`,
        },
      },
    })
    this.$echo.connector.pusher.connection.bind(
      'state_change',
      function (states) {
        store.commit('setCurrentWebSocketConnectionStatus', states.current)
      }
    )
    // this.$echo.connector.pusher.connection.state
    this.$echo.connector.signin()
  }
  disconnect() {
    if (this.$echo !== null) {
      this.$echo.disconnect()
    }
    this.$echo = null
  }

  static getInstance() {
    if (this.instance === null) {
      this.instance = new Ws()
    }
    return this.instance
  }
}

export default Ws
