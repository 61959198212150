import api from '@/http'

const state = () => ({
  availableStadiums: null,
  selectedStadiumId: null,
})

const getters = {}

const actions = {
  getAvailableStadiums({ commit }, gameId) {
    return new Promise((resolve, reject) => {
      api
        .get(`games/${gameId}/available-stadiums`)
        .then(({ data }) => {
          commit('setAvailableStadiums', data)
          commit('setSelectedStadiumId', data.stadium_id)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateStadiumPlacesCost(ctx, data) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/games/${data.gameId}/available-stadiums/${data.stadiumId}/prices`,
          data.payload
        )
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.availableStadiums = null
  },
  setAvailableStadiums(state, availableStadiums) {
    state.availableStadiums = availableStadiums
  },
  setSelectedStadiumId(state, selectedStadiumId) {
    state.selectedStadiumId = selectedStadiumId
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
