export default [
  'dribble_centermagnet',
  'dribble_offensiveness',
  'position_defense_depth_factor',
  'position_defense_width_factor',
  'position_offense_width_factor',
  'position_offense_midfieldfocus',
  'position_defense_sidefocus_strength',
  'position_defense_microfocus_strength',
  'position_defense_midfieldfocus',
  'position_offense_depth_factor',
  'position_offense_microfocus_strength',
  'position_offense_sidefocus_strength',
]
