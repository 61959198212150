import api from '@/http'
import VueCookies from 'vue-cookies'

const state = () => ({
  manager: null,
  referrer: VueCookies.get('__referrer'),
})

const getters = {}

const actions = {
  getManagerByLogin({ commit }, login) {
    return new Promise((resolve, reject) => {
      api
        .get(`managers/${login}`)
        .then(({ data, response }) => {
          commit('setManager', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateManager(ctx, manager) {
    return new Promise((resolve, reject) => {
      api
        .put('profile', manager)
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  uploadAvatar(ctx, image) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('avatar', image)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      api
        .post(`profile/avatar`, formData, config)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteAvatar() {
    return new Promise((resolve, reject) => {
      api
        .delete(`profile/avatar`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getRefferals(ctx, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/profile/referrals`, { params })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getManagerFriends(ctx, payload) {
    return new Promise((resolve, reject) => {
      api
        .get(`managers/${payload.login}/friends`, {
          params: { page: payload.page, 'per-page': payload.perPage },
        })
        .then(({ data, response }) => {
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  sendFriendRequest(ctx, login) {
    return new Promise((resolve, reject) => {
      api
        .post(`managers/${login}/friends/send-request`)
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.manager = null
  },
  setManager(state, manager) {
    state.manager = manager
  },
  setReferrer(state, referrer) {
    state.referrer = referrer

    VueCookies.set('__referrer', referrer, '1d', null)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
