export default {
  go_to_main: 'Вернуться на главную',
  site_close: 'Сайт временно недоступен',
  maintenance: 'В настоящий момент на сайте ведутся технические работы',
  after_maintenance:
    'Когда сайт заработает Вы будете перенаправлены на главную страницу',
  blocked_ip: 'Ваш IP адрес был добавлен в чёрный список',
  try_again: 'Попробуйте снова через',
  seconds: 'секун|ду|ды|д',
  no_inet: 'Не удалось установить соединение с сервером',
  inet_connection: 'Проверьте Ваше подключение к Интернету',
  reload: 'Перезагрузить',
  errors_happens: 'Ошибки случаются',
  deleted_page: 'Возможно, страница была перемещена или удалена.',
  server_request_error:
    'Ошибка! За последнее время Вы сделали слишком много запросов',
}
