import api from '@/http'

const state = () => ({
  incomingFriendsCount: null,
  outgoingFriendsCount: null,
})

const getters = {}

const actions = {
  getIncomingRequests({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .get('/friends/requests', { params })
        .then(({ data, response }) => {
          commit('setIncomingFriendsCount', data.length)
          resolve({ data, response })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getOutgoingRequests({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/friends/requests/sent')
        .then(({ data }) => {
          commit('setOutgoingFriendsCount', data.length)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteFriend(ctx, friendId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/friends/${friendId}`, { display404ErrorMessage: true })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  acceptFriendRequest(ctx, requestId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/friends/requests/${requestId}/accept`, {
          display404ErrorMessage: true,
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  declineFriendRequest(ctx, requestId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/friends/requests/${requestId}/decline`, {
          display404ErrorMessage: true,
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelFriendRequest(ctx, requestId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/friends/requests/${requestId}/cancel`, {
          display404ErrorMessage: true,
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.incomingFriendsCount = null
    state.outgoingFriendsCount = null
  },
  setIncomingFriendsCount(state, incomingFriendsCount) {
    state.incomingFriendsCount = incomingFriendsCount
  },
  setOutgoingFriendsCount(state, outgoingFriendsCount) {
    state.outgoingFriendsCount = outgoingFriendsCount
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
