export default [
  {
    top: 43.71859296482412,
    left: 5.641025641025641,
    pos: 'cf1',
  },
  {
    top: 25.125628140703515,
    left: 22.22222222222222,
    pos: 'st3',
  },
  {
    top: 43.71859296482412,
    left: 22.22222222222222,
    pos: 'st2',
  },
  {
    top: 62.311557788944725,
    left: 22.22222222222222,
    pos: 'st1',
  },
  {
    top: 25.125628140703515,
    left: 34.01709401709402,
    pos: 'am3',
  },
  {
    top: 43.71859296482412,
    left: 34.01709401709402,
    pos: 'am2',
  },
  {
    top: 62.311557788944725,
    left: 34.01709401709402,
    pos: 'am1',
  },

  {
    top: 6.532663316582915,
    left: 45.72649572649573,
    pos: 'rm',
  },

  {
    top: 25.125628140703515,
    left: 45.72649572649573,
    pos: 'cm3',
  },

  {
    top: 43.71859296482412,
    left: 45.72649572649573,
    pos: 'cm2',
  },

  {
    top: 62.311557788944725,
    left: 45.72649572649573,
    pos: 'cm1',
  },

  {
    top: 80.90452261306532,
    left: 45.72649572649573,
    pos: 'lm',
  },

  {
    top: 25.125628140703515,
    left: 57.692307692307686,
    pos: 'dm3',
  },

  {
    top: 43.71859296482412,
    left: 57.692307692307686,
    pos: 'dm2',
  },

  {
    top: 62.311557788944725,
    left: 57.692307692307686,
    pos: 'dm1',
  },

  {
    top: 6.532663316582915,
    left: 69.74358974358974,
    pos: 'rb',
  },

  {
    top: 25.125628140703515,
    left: 69.74358974358974,
    pos: 'cd3',
  },

  {
    top: 43.71859296482412,
    left: 69.74358974358974,
    pos: 'cd2',
  },

  {
    top: 62.311557788944725,
    left: 69.74358974358974,
    pos: 'cd1',
  },

  {
    top: 80.90452261306532,
    left: 69.74358974358974,
    pos: 'lb',
  },
  {
    top: 43.71859296482412,
    left: 86.41025641025641,
    pos: 'gk',
  },
  {
    pos: 'r1',
    reserve: true,
  },
  {
    pos: 'r2',
    reserve: true,
  },
  {
    pos: 'r3',
    reserve: true,
  },
  {
    pos: 'r4',
    reserve: true,
  },
  {
    pos: 'r5',
    reserve: true,
  },
  {
    pos: 'r6',
    reserve: true,
  },
  {
    pos: 'r7',
    reserve: true,
  },
  {
    pos: 'r8',
    reserve: true,
  },
]
