export default {
    general_information: 'общая информация',
    information_centre: 'Информационный центр',
    help: 'помощь',
    paid_services: 'платные услуги',
    about_the_project: 'О проекте',
    about_the_team: 'О команде',
    contract_offer: 'Договор оферты',
    privacy_policy: 'Политика конфиденциальности',
    project_rules: 'Правила проекта',
    any_questions: 'Возникли вопросы?',
    contact_us: 'Свяжитесь с нами!',
    where_to_spend: 'Куда потратить?',
    write: 'Написать',
}
