export default {
  enter_new_stadium_name: 'Введите название стадиона',
  info: 'Информация',
  status: 'Статус',
  object: 'Объект',
  construction: 'Строительство',
  sectors: 'Сектора:',
  capacity: 'Вместимость:',
  capacity_info: 'Вместимость',
  bench: 'Скамейки:',
  soft: 'Мягкие:',
  plastic: 'Пластиковые:',
  vip: 'ВИП:',
  bench_info: 'Скамеек',
  soft_info: 'Мягких мест',
  plastic_info: 'Пластиковых мест',
  vip_info: 'ВИП мест',
  game_operating_cost: 'Сумма за эксплуатацию:',
  stadium_cost: 'Общая стоимость стадиона:',
  cost: 'Стоимость',
  price: 'Цена',
  plot: 'Земля',
  sector: 'Сектор',
  field: 'Газон',
  lighting: 'Освещение',
  scoreboard: 'Табло',
  under_tribune_room: 'Подтребунное помещение',
  shop: 'Фан-магазин',
  warehouse: 'Склад',
  sector_building: 'Строительство сектора',
  sector_built: 'Сектор построен',
  places_building: 'Строительство мест',
  places_building_title: 'Строительство мест:',
  places_built: 'Застроен местами',
  places: 'Места',
  building: 'Строится',
  built: 'Построен',
  build: 'Построить',
  built_at: 'До конца строительства осталось {builtAt}',
  built_at_info: 'До конца строительства',
  plot_plots: 'участ|ок|ка|ков',
  new_building: 'Новая постройка',
  bought_plots: 'Участков земли',
  construction_time: 'Время строительства',
  construction_time_places: 'Время строительства:',
  not_enough_plots: 'У Вас недостаточно участков земли для строительства',
  buy_plots: 'Купить землю',
  not_built_sector: 'Не построенный сектор',
  needed_plots: 'Необходимое кол-во участков',
  sector_cost: 'Стоимость постройки',
  free_plots: 'Свободные участки: {amount} шт.',
  plots_count: 'Сколько участков земли вы хотите купить?',
  general_count: 'Общая сумма: ',
  success_plots_buy: 'Вы успешно приобрели землю!',
  success_places_buy: 'Вы успешно начали строительство мест!',
  success_sector_buy: 'Вы успешно начали строительство сектора!',
  success_object_buy: 'Вы успешно начали строительство объекта!',
  built_up: 'Застроено: ',
  building_cost: 'Стоимость строительства: ',
  building_coefficient: 'Коэффициент застройки',
  building_interval: 'Интервал застройки',
  build_today: 'Строительство будет завершено сегодня',
  stadium_name: 'Стадион {name}',
}
