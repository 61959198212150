export default {
  forgot_password: 'Забыли пароль?',
  specify_email:
    'Укажите свой email, под которым вы зарегистрированы на сайте и на него будет отправлена информация о восстановлении пароля',
  restore_password_info:
    'Информация о восстановлении пароля была отправлена на ваш e-mail!',
  new_password: 'Введите новый пароль',
  repeat_new_password: 'Повторите новый пароль',
  update_password: 'Обновить пароль',
  update_password_success: 'Вы успешно обновили свой пароль!',
  failed_update_password:
    'Не удалось обновить пароль. Ссылка более не действительна, попробуйте отправить заново!',
  passwords_do_not_match: 'Пароли не совпадают!',
}
