export default {
  general: 'Общие',
  login_password: 'Логин / Пароль',
  tab_email: 'Эл.почта',
  enter_new_team_name: 'Введите название команды',
  change_team_emblem: 'Сменить эмблему команды',
  choose_emblem_from_suggested: 'Выберите эмблему из предложенного списка',
  upload_team_logo: 'Загрузить логотип команды',
  choose_best_game_hours: 'Выбрать лучшее время для проведения игр',
  choose_hour: 'Выберите час',
  from: 'От',
  to: 'До',
  new_login: 'Новый логин',
  current_password: 'Текущий пароль',
  confirmation_code_sent:
    'Код-подтверждение был отправлен на указанный e-mail.',
  enter_code: 'Введите код',
  login_changed_successfully: 'Ваш логин был успешно изменен',
  email_changed_successfully: 'Ваш email был успешно изменен',
  password_changed_successfully: 'Ваш пароль был успешно изменен',
  get_code: 'Получить код',
  code_will_sent_to_email: 'Код подверждение будет отправлен на текущий e-mail',
  change_login: 'Сменить логин',
  change_email: 'Сменить эл.почту',
  change_password: 'Сменить пароль',
  wrong_specified_passwords: 'Введенные пароли не совпадают',
  old_password: 'Старый пароль',
  new_password: 'Новый пароль',
  new_password_confirmation: 'Повторите новый пароль',
}
