export default {
  previous: 'Назад',
  next: 'Дальше',
  stop: 'Закончить тур',
  start_tour: 'Пройти обучение',
  understand: 'Понятно',
  restart_tour: 'Вы всегда можете пройти обучение заново, перейдя в ',
  restart_tour_route: '"Настройки" - "Общие"',
  steps: {
    menu_team: {
      header: 'Команда',
      content: 'В разделе "Команда" Вы можете ознакомиться со своей командой',
    },
    menu_team_roster: {
      header: 'Ростер',
      content:
        'В разделе "Команда" - "Ростер" Вы можете ознакомиться со всей информацией о Вашей команде',
    },
    roster_level: {
      header: 'Уровень',
      content:
        'Это Уровень команды. Уровень команды - это ключевой показатель, определяющий силу и компетентность вашей футбольной команды',
    },
    roster_elo: {
      header: 'Уровень ЭЛО',
      content:
        'Это Уровень ЭЛО. Уровень ЭЛО - числовой показатель, отражающий уровень компетентности и успехов вашей команды в соревнованиях',
    },
    roster_team: {
      header: 'Список футболистов',
      content:
        'Здесь Вы можете посмотреть всех игроков Вашей команды по позициям, а также их текущую усталость и уровень прокачки',
    },
    roster_tournament_table: {
      header: 'Турнирная таблица',
      content:
        'Здесь Вы можете посмотреть текущее положение Вашей команды в турнирной таблице',
    },
    menu_team_second: {
      content: 'Перейдём в "Команда" - "Маркетинг"',
    },
    menu_marketing: {
      header: 'Маркетинг',
      content:
        'В разделе "Команда" - "Маркетинг" Вы можете построить магазин аксессуаров, а также заключить спонсорский и рекламный контракты',
    },
    marketing_shop: {
      header: 'Магазин',
      content:
        'Здесь после постройки находится магазин атрибутики. В нём Вы можете закупать футболки, шарфы и многое другое, а затем продавать их болельщикам на стадионе',
    },
    marketing_advertising_contracts: {
      header: 'Рекламные контракты',
      content:
        'Здесь Вы можете подписать рекламный контракт, если ещё не подписали или посмотреть на уже подписанные рекламные контракты',
    },
    marketing_advertising_offers: {
      header: 'Рекламные предложения',
      content:
        'Здесь Вы можете подписать рекламный контракт по своим предпочтениям, если у Вас ещё нет подписанного, выбрав из предложенных вариантов',
    },
    menu_team_third: {
      content:
        'В разделе "Команда" - "Состав на игру" Вы можете ознакомиться с составом Вашей команды',
    },
    menu_game_squad: {
      header: 'Состав на игру',
      content:
        'В разделе "Команда" - "Состав на игру" Вы можете выставить желаемый состав на будущие игры, определить игроков на замены, а также выставить тактику игры',
    },
    game_squad_field_formation: {
      header: 'Основной состав',
      content:
        'Это основной состав Вашей команды на будущие игры. Для выставления игроков, переставьте игроков из "Список игроков" на соответствующие позиции',
    },
    game_squad_reserved_players: {
      header: 'Запасные игроки',
      content:
        'Это запасные игроки Вашей команды на будущие игры. Для выставления игроков, переставьте игроков из "Список игроков" в пустые ячейки',
    },
    game_squad_players_list: {
      header: 'Список игроков',
      content:
        'Здесь находяться все игроки Вашей команды. Для выставления их на позицию или на замену необходимо выделить игрока, кликнув по нему, а затем кликнуть на необходимую ячейку',
    },
    game_squad_save_changes_players_list: {
      content:
        'После выставления Ваших основных и запасных игроков на позиции, сохраните изменения',
    },
    game_squad_settings: {
      content: 'Во вкладке "Тактика" определите стиль игры Вашей команды',
    },
    game_squad_settings_parameters: {
      header: 'Тактика',
      content:
        'Это тактика игры Вашей команды на будущие игры. Она определяет стиль игры Вашей команды. Для изменения тактики необходимо перетащить ползунки в соответствующие позиции',
    },
    game_squad_save_changes_settings_parameters: {
      content: 'После выставления тактики Вашей команды, сохраните изменения',
    },
    menu_transfers: {
      header: 'Трансферы',
      content:
        'В разделе Трансферы Вы можете ознакомиться с трансферным листом, текущими переговорами Вашей команды а также выставить своего игрока на трансфер или отдать в аренду',
    },
    transfer_list: {
      header: 'Список трансферов',
      content:
        'Здесь Вы можете ознакомится с перечнем игроков выставленных на продажу, если такие имеются',
    },
    menu_infrastructure: {
      header: 'Инфраструктура',
      content:
        'В разделе "Инфраструктура" Вы можете ознакомиться с офисом и стадионом Вашей команды и их возможностями',
    },
    menu_infrastructure_office: {
      header: 'Офис',
      content:
        'В разделе "Инфраструктура" - "Офис" Вы можете ознакомиться с Офисом, Тренировочной базой, СПА-центром и Реабилитационным центром Вашей команды',
    },
    office_built_buildings: {
      header: 'Построенные здания',
      content:
        'Здесь можете ознакомится с построенными зданиями в Офисе Вашей команды',
    },
    offers: {
      header: 'Контракты',
      content:
        'Здесь можете ознакомится с состоянием Ваших рекламных и спонсорских контрактов',
    },
    office_objects_under_construction: {
      header: 'Строения офиса',
      content:
        'Здесь Вы можете ознакомится с процессом строительства объектов в Офисе Вашей команды',
    },
    training_base: {
      header: 'Тренировочная база',
      content:
        'Это объекты Тренировочной базы. После постройки всех объектов Тркенировочная база станет доступной. В ней Вы сможете тренировать своих игроков для достижения лучших результатов',
    },
    spa: {
      header: 'СПА-центр',
      content:
        'Это объекты СПА-центра. После постройки всех объектов СПА-центр станет доступный. В нём Ваши игроки смогут отдохнуть и восстановить свою физическую форму',
    },
    medical_center: {
      header: 'Реабилитационный центр',
      content:
        'Это объекты Реабилитационного центра. После постройки всех объектов Реабилитационный центр станет доступный. В нём Ваши игроки смогут получить профессиональную медицинскую помощь и ускорить лечение травм',
    },
    menu_infrastructure_second: {
      content:
        'Перейдём в раздел "Инфраструктура" - "Стадион" Для ознакомления со стадионом Вашей команды',
    },
    menu_infrastructure_stadium: {
      header: 'Стадион',
      content:
        'В разделе "Инфраструктура" - "Стадион" Вы можете построить сектора для своего стадиона а также застроить их местами, для продажи билетов',
    },
    stadium_sectors: {
      header: 'Сектора стадиона',
      content:
        'Это сектора Вашего стадиона. Для постройки сектора - необходимо кликнуть по соответствующему названию сектора. Когда сектор будет построен, его можно будет застроить местами',
    },
    stadium_change_name: {
      header: 'Название стадиона',
      content:
        'Здесь Вы можете изменить название Вашего стадиона, в любое удобное для Вас время',
    },
    stadium_info: {
      header: 'Информация о стадионе',
      content:
        'Здесь во вкладке "Информация" Вы можете ознакомиться с информацией о Вашем стадионе. Во вкладке "Строительство" Вы можете ознакомиться с текущими и оконченными строительствами на Вашем стадионе',
    },
    play_button_dropdown: {
      header: 'Кнопка "Играть"',
      content:
        'Кликнув по кнопке "Играть" Вы можете начать товарищескую игру или зарегистрироваться в быстрый турнир',
    },
    play_friendly_game: {
      header: 'Товарищеская игра',
      content:
        'Кликнув по кнопке "Товарищеская" Вы перейдёте на страницу где можно выбрать себе соперника для товарищеской игры',
    },
    game_waiters_chat: {
      header: 'Общий чат',
      content:
        'У Вас всегда есть возможность пообщаться с другими игроками, находящимися в онлайне, с помощью чата. Для вызова чата с любого другого места в приложении воспользуйтесь горячими клавишами "SHIFT + C" или кликните по соответствующей иконке в меню',
    },
    game_waiters_table: {
      header: 'Команды в ожидании',
      content:
        'Здесь Вы можете посмотреть на все команды, которым в данный момент Вы можете предложить сыграть с Вами товарищескую игру. Для выбора соперника кликните на круг напротив названия команды',
    },
    game_waiters_clean: {
      header: 'Отмена выбора',
      content:
        'Клик по данной кнопке отменит сделанный Вами раннее выбор команды для приглашения на товарищескую игру',
    },
    game_waiters_add_to_waiters: {
      header: 'Добавление в список',
      content:
        'Клик по данной кнопке добавит Вас в список доступных команд для приглашения на товарищескую игру',
    },
    game_waiters_send_game_request: {
      header: 'Отправление запроса',
      content:
        'Клик по данной кнопке отправит запрос, выбранной ранее команде, на подтверждение приглашения на товарищескую игру. После того как соперник подтвердит приглашение - начнётся игра!',
    },
    restart_tour: {
      content:
        'Вы всегда можете пройти тур заново, кликнув по соответствующей кнопке в списке',
    },
  },
  yes: 'Да',
  no: 'Нет',
  continue_tour: 'Вы хотите продолжить обучение?',
}
