import api from '@/http'

const state = () => ({
  shopGoods: null,
  selectedSponsorTab: 'sponsors-contracts',
  selectedAdsTab: 'advertising-contracts',
  signedSponsors: [],
  availableSponsors: [],
  signedAdsContracts: [],
  availableAdsContracts: [],
})

const getters = {}

const actions = {
  getShopGoods({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/manager/team/shop-goods')
        .then(({ data }) => {
          commit('setShopGoods', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  buyShopGoods(ctx, data) {
    return new Promise((resolve, reject) => {
      const payload = {
        quantity: data.quantity,
      }
      api
        .post(`/manager/team/shop-goods/${data.shopGoodId}/buy`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSignedSponsors({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/manager/team/sponsors/signed')
        .then(({ data }) => {
          commit('setSignedSponsors', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAvailableSponsors({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/manager/team/sponsors/available')
        .then(({ data }) => {
          commit('setAvailableSponsors', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  signSponsor(ctx, sponsorId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/manager/team/sponsors/${sponsorId}/sign`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAvailableAds({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/manager/team/ads/available')
        .then(({ data }) => {
          commit('setAvailableAdsContracts', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSignedAdvertisingContracts({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('/manager/team/ads/signed')
        .then(({ data }) => {
          commit('setSignedAdsContracts', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  signAdvertising(ctx, advId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/manager/team/ads/${advId}/sign`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.shopGoods = null
    state.signedSponsors = []
    state.availableSponsors = []
    state.signedAdsContrants = []
    state.availableAdsContracts = []
  },
  setShopGoods(state, shopGoods) {
    state.shopGoods = shopGoods
  },
  setSignedSponsors(state, sponsors) {
    state.signedSponsors = sponsors
  },
  setAvailableSponsors(state, sponsors) {
    state.availableSponsors = sponsors
  },
  setSignedAdsContracts(state, ads) {
    state.signedAdsContracts = ads
  },
  setAvailableAdsContracts(state, ads) {
    state.availableAdsContracts = ads
  },
  setSelectedSponsorTab(state, tabName) {
    state.selectedSponsorTab = tabName
  },
  setSelectedAdsTab(state, tabName) {
    state.selectedAdsTab = tabName
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
