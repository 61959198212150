export default {
  type: 'Тип',
  scope: 'Сфера',
  categories: {
    development: 'Строительство',
    transfers: 'Трансферы',
    finance: 'Финансы',
    bonuses: 'Бонусы',
    other: 'Другое',
  },
  directions: {
    credit: 'Доход',
    debit: 'Расход',
  },
  orders: {
    date_desc: 'От новых к старым',
    date_asc: 'От старых к новым',
    sum_asc: 'От наименьшей суммы к наибольшей',
    sum_desc: 'От наибольшей суммы к наименьшей',
  },
  date: 'Дата',
  description: 'Описание',
  amount: 'Сумма',
  reset_filters: 'Сбросить фильтры',
  income: 'Доходы',
  analysis: 'Анализ',
  title: 'Финансовый отчет',
  total: 'Всего',
  not_transactions: 'Пока что нет записей для отчета',
  free_vd: 'Свободно от обязательств: {free_vd}',
  frozen_vd: 'Заморожено: {frozen_vd}',
  statistic: {
    title: 'Статистика',
    for_day: 'За предыдущие сутки',
    for_week: 'За последние 7 дней',
    total_time: 'От начала сезона',
    not_data: 'Пока что нет данных для анализа',
  },
}
