import api from '@/http'

const state = () => ({})

const getters = {}

const actions = {
  updateTeam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .patch(`settings/team`, payload)
        .then(({ data }) => {
          commit('manager/setManager', data, { root: true })
          commit('manager/setTeam', data.team, { root: true })
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadOwnTeamImage({ commit }, image) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('own_image', image)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      api
        .post(`settings/team/own-image`, formData, config)
        .then(({ data }) => {
          commit('manager/setManager', data, { root: true })
          commit('manager/setTeam', data.team, { root: true })
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteOwnTeamImage({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .delete(`settings/team/own-image`)
        .then(({ data }) => {
          commit('manager/deleteOwnTeamImage', null, { root: true })
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateManagerSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .patch(`settings/manager`, payload)
        .then(({ data }) => {
          commit('manager/setManager', data, { root: true })
          commit('manager/setTeam', data.team, { root: true })
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeLogin(ctx, payload) {
    return ctx.dispatch('postPayloadRequest', {
      url: 'settings/change-login',
      payload,
    })
  },
  verifyChangeLogin(ctx, payload) {
    return ctx.dispatch('postPayloadRequest', {
      url: 'settings/change-login/verify',
      payload,
    })
  },
  changePassword(ctx, payload) {
    return ctx.dispatch('postPayloadRequest', {
      url: 'settings/change-password',
      payload,
    })
  },
  verifyChangePassword(ctx, payload) {
    return ctx.dispatch('postPayloadRequest', {
      url: 'settings/change-password/verify',
      payload,
    })
  },
  changeEmail(ctx, payload) {
    return ctx.dispatch('postPayloadRequest', {
      url: 'settings/change-email',
      payload,
    })
  },
  verifyChangeEmail(ctx, payload) {
    return ctx.dispatch('postPayloadRequest', {
      url: 'settings/change-email/verify',
      payload,
    })
  },
  postPayloadRequest(ctx, data) {
    return new Promise((resolve, reject) => {
      api
        .post(data.url, data.payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
