import api from '@/http'

const state = () => ({})

const getters = {}

const actions = {
  getTeamRoster(ctx, teamId) {
    return new Promise((resolve, reject) => {
      api
        .get(`teams/${teamId}/roster/`)
        .then(({ data, response }) => {
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
