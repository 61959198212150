import api from '@/http'
import _ from 'lodash'

const state = () => ({
  player: null,
  activeIncreasePlayerParameter: null,
})

const getters = {
  isPlayerFullIncreased: (state) =>
    _.round(state.player.parameters_increased, 2) === 100,
}

const actions = {
  getPlayer({ commit }, playerId) {
    return new Promise((resolve, reject) => {
      api
        .get(`players/${playerId}`)
        .then(({ data, response }) => {
          commit('setPlayer', data)
          resolve(data, response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  pumpPlayerParameter(ctx, data) {
    return new Promise((resolve, reject) => {
      api
        .post(`/players/${data.playerId}/pump-parameter`, data.payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  bulkPumpPlayerParameters(ctx, data) {
    return new Promise((resolve, reject) => {
      api
        .post(`/players/${data.playerId}/bulk-pump-parameters`, data.payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.player = null
    state.activeIncreasePlayerParameter = null
  },
  setPlayer(state, player) {
    state.player = player
  },
  setActiveIncreasePlayerParameter(state, activeIncreasePlayerParameter) {
    state.activeIncreasePlayerParameter = activeIncreasePlayerParameter
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
