import api from '@/http'

const state = () => ({
  news: null,
})

const getters = {}

const actions = {
  getNewsLinks({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('news/links')
        .then(({ data }) => {
          commit('setNews', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    state.news = null
  },
  setNews(state, news) {
    state.news = news
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
