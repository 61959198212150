import Vue from 'vue'
import Vuex from 'vuex'
import global from './modules/global'
import manager from './modules/manager'
import teamTemplate from './modules/team_template'
import game from './modules/game'
import playGame from './modules/play_game'
import chat from './modules/chat'
import statisticGame from './modules/statistic_game'
import country from './modules/country'
import createTeam from './modules/create_team'
import tournament from './modules/tournament'
import player from './modules/player'
import teamGames from './modules/team_games'
import teamRoster from './modules/team_roster'
import search from './modules/search'
import mail from './modules/mail'
import profile from './modules/profile'
import bank from './modules/bank'
import settings from './modules/settings'
import vdReport from './modules/vd_report'
import news from './modules/news'
import stadium from './modules/stadium'
import transfers from './modules/transfers'
import availableStadiums from './modules/available_stadiums'
import marketing from './modules/marketing'
import notifications from './modules/notifications'
import quickTournaments from './modules/quick_tournaments'
import office from './modules/office'
import block from './modules/block'
import friends from './modules/friends'
import support from './modules/support'
import shop from './modules/shop'
import inventory from './modules/inventory'
import takeTeam from './modules/take_team'

Vue.use(Vuex)

export default new Vuex.Store({
  ...global,
  modules: {
    manager,
    teamTemplate,
    game,
    playGame,
    chat,
    statisticGame,
    country,
    createTeam,
    tournament,
    player,
    teamGames,
    teamRoster,
    search,
    mail,
    profile,
    bank,
    settings,
    vdReport,
    news,
    stadium,
    transfers,
    availableStadiums,
    marketing,
    notifications,
    quickTournaments,
    office,
    block,
    friends,
    support,
    shop,
    inventory,
    takeTeam,
  },
})
