export function setLocalStorageItem(key, data, ttl) {
  let item = {
    data: data,
    expiry: ttl ? Date.now() + ttl : null,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getLocalStorageItem(key) {
  let item = localStorage.getItem(key)
  if (!item) {
    return null
  }

  item = JSON.parse(item)
  if (item.expiry && Date.now() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.data
}
