import api from '@/http'
import _ from 'lodash'
import Moment from 'moment-timezone'

const state = () => ({
  conversations: [],
  messages: [],
  companion: null,
})

const getters = {
  latestConversations: (state) =>
    _.reverse(
      _.sortBy(state.conversations, [
        (c) =>
          c.last_message !== null
            ? Moment(c.last_message.created_at).unix()
            : 0,
      ])
    ),
  countUnreadMessages: (state) =>
    state.conversations.reduce((a, b) => a + (b.unread_messages_count || 0), 0),
    
messagesByDay: (state) => {
  const groupedMessages = _.groupBy(state.messages, (m) => 
    Moment(m.created_at).format('YYYY-MM-DD')
  )
  const data = []
  for (const date in groupedMessages) {
    data.push({
      date: Moment(date),
      messages: groupedMessages[date],
    })
  }

  return data
},
}

const actions = {
  getConversations({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`conversations`)
        .then(({ data }) => {
          commit('setConversations', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getConversationMessages({ commit }, conversationId) {
    return new Promise((resolve, reject) => {
      api
        .get(`conversations/${conversationId}/messages`)
        .then(({ data, response }) => {
          commit('setMessages', data)
          if (response?.data?.meta?.companion) {
            commit('setCompanion', response?.data?.meta?.companion)
          } else {
            {commit('setCompanion', null)}
          }
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createNewMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      const payload = {
        text: data.text,
      }
      api
        .post(`conversations/${data.conversationId}/new-message`, payload)
        .then(({ data }) => {
          commit('addNewMessage', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  markMessageAsRead({ commit }, messageId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/conversation-messages/${messageId}/mark-as-read`)
        .then(({ data }) => {
          commit('markMessagesAsRead', {
            readAt: Moment(),
            messageIds: [messageId],
          })
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resolveConversationByManager(ctx, managerId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/managers/${managerId}/conversation/resolve`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateLastMessage({ state, commit, dispatch, rootState }, message) {
    for (const conversation of state.conversations) {
      if (conversation.id === message.conversation_id) {
        conversation.last_message = message
        if (message.from.id !== rootState.manager.manager.id) {
          conversation.unread_messages_count += 1
        }
        commit('setConversations', state.conversations)
        return
      }
    }
    dispatch('getConversations')
  },
  updateUnreadMessagesCount(
    { state, commit, dispatch },
    { conversationId, messageIds }
  ) {
    for (const conversation of state.conversations) {
      if (conversation.id === conversationId) {
        conversation.unread_messages_count -= messageIds.length
        if (conversation.unread_messages_count < 0) {
          conversation.unread_messages_count = 0
        }
        commit('setConversations', state.conversations)
        return
      }
    }
    dispatch('getConversations')
  },
}

const mutations = {
  resetState(state) {
    state.conversations = []
    state.messages = []
    state.companion = null
  },
  resetConversationMessagesState(state) {
    state.messages = []
    state.companion = null
  },
  setConversations(state, conversations) {
    state.conversations = conversations
  },
  setMessages(state, messages) {
    state.messages = messages
  },
  setCompanion(state, companion) {
    state.companion = companion
  },
  setCompanionLastActiveAt(state, lastActiveAt) {
    if (state.companion) {
      state.companion.last_active_at = lastActiveAt
    }
  },
  addNewMessage(state, message) {
    if (state.messages.some((m) => m.id === message.id)) {
      return
    }
    state.messages = [message, ...state.messages]
  },
  markMessagesAsRead(state, { readAt, messageIds }) {
    for (const message of state.messages) {
      if (messageIds.includes(message.id)) {
        message.read_at = readAt
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
