export default {
  tokens: 'Токены',
  count: 'шт',
  token_buy: 'Хотите купить {tokenType} ?',
  success_token_buy: 'Вы успешно приобрели {tokenType}',
  provider_link:
    'Если Вы не были перенаправлены автоматически, то, пожалуйста, перейдите по ссылке самостоятельно, кликнув сюда',
  pay_success: 'Ваш счет был успешно пополнен.',
  pay_gratitude: 'Благодарим за поддержку проекта!',
  pay_error: 'Не удалось совершить оплату!',
}
