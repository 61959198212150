import api from '@/http'
import hash from 'object-hash'
import _ from 'lodash'

const defaultState = {
  transactions: [],
  query: {
    categories: [],
    directions: [],
    page: 1,
    season_number: null,
    order: 'date_desc',
  },
  statistic: null,
  lastQueryHash: null,
  meta: null,
}

const state = () => _.cloneDeep(defaultState)

const getters = {}

const actions = {
  getVdTransactions({ commit }, httpQuery) {
    return new Promise((resolve, reject) => {
      commit('setLastQueryHash', hash(httpQuery))
      api
        .get('vd-transactions', {
          params: httpQuery,
        })
        .then(({ data, response }) => {
          if (response?.data?.meta) {
            commit('setMeta', response.data.meta)
          }
          commit('setTransactions', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getVdTransactionsStatistic({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('vd-transactions/statistic')
        .then(({ data }) => {
          commit('setStatistic', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  resetState(state) {
    Object.assign(state, _.cloneDeep(defaultState))
  },
  resetQuery(state) {
    Object.assign(state.query, _.cloneDeep(defaultState.query))
  },
  setQueryFromRoute(state, routeQuery) {
    Object.assign(state.query, routeQuery)
    if (
      routeQuery.categories?.length &&
      !Array.isArray(routeQuery.categories)
    ) {
      state.query.categories = [routeQuery.categories]
    }
    if (routeQuery.direction) {
      state.query.directions = [routeQuery.direction]
      delete state.query.direction
    }
  },
  setQueryPage(state, page) {
    state.query.page = page
  },
  setSeasonNumber(state, seasonNumber) {
    state.query.season_number = seasonNumber
  },
  setMeta(state, meta) {
    state.meta = meta
  },
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setLastQueryHash(state, queryHash) {
    state.lastQueryHash = queryHash
  },
  setStatistic(state, statistic) {
    state.statistic = statistic
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
